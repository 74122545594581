import { useDispatch, useSelector } from 'react-redux';
import { orientationSelector } from '../../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.selectors';
import { useCallback, useEffect } from 'react';
import { setOrientation as setOrientationAction } from '../../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.slice';
import { videoResolutionSeletor } from '../../../../store/videoEditor/videoEditor.selectors';

export const useOrientation = () => {
  const dispatch = useDispatch();

  const orientationStore = useSelector(orientationSelector);
  const resolution = useSelector(videoResolutionSeletor);

  const orientation =
    orientationStore ||
    (resolution.width > resolution.height ? 'landscape' : resolution.width < resolution.height ? 'portrait' : 'square');

  const setOrientation = useCallback(
    (newOrientation: 'portrait' | 'landscape' | 'square') => {
      dispatch(setOrientationAction(newOrientation));
    },
    [dispatch],
  );

  useEffect(() => {
    if (orientationStore === null) {
      dispatch(setOrientationAction(orientation));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orientationStore]);

  return { orientation, setOrientation };
};
