import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import GalleryIcon from '../../../assets/icons/biteIntro/gallery.svg';
import CreateMediaIcon from '../../../assets/icons/biteIntro/create-media-icon.svg';
import ImportMediaIcon from '../../../assets/icons/creationFlow/import.svg';
import BitesMediaIcon from '../../../assets/icons/biteIntro/bites.svg';
import FacebookSmallIcon from '../../../assets/icons/biteIntro/facebook-grey.svg';
import TikTokSmallIcon from '../../../assets/icons/biteIntro/tiktok-grey.svg';
import PowerpointSmallIcon from '../../../assets/icons/biteIntro/powerpoint-grey.svg';
import GoogleSlidesSmallIcon from '../../../assets/icons/biteIntro/google-slides.svg';
import YoutubeSmallIcon from '../../../assets/icons/biteIntro/youtube-media-icon.svg';
import InstagramSmallIcon from '../../../assets/icons/biteIntro/instagram.svg';
import ShadowedButton from '../ShadowedButton';
import { calcHeight, isWeb } from '../../../utils/dimensions';
import styled, { useTheme } from 'styled-components/native';
import { galleryBtnDataSet, importBtnDataSet, videoBtnDataSet } from './createIntroButtons.constants';

interface IProps {
  createMediaTitle: string;
  createMediaDescription: string;
  onCreateMediaPress: () => void;
  onUploadPress?: () => void;
  onImportMediaPress: () => void;
  onOpenFromGalleryPress?: () => void;
  onWorkspaceVideosPress?: () => void;
  onDraftPress: () => void;
  isDraftDisabled?: boolean;
  isCreateMediaDisabled?: boolean;
}

const CreateIntroButtons: React.FC<IProps> = ({
  createMediaTitle,
  createMediaDescription,
  onCreateMediaPress,
  onUploadPress,
  onOpenFromGalleryPress,
  onImportMediaPress,
  onWorkspaceVideosPress,
  onDraftPress,
  isDraftDisabled,
  isCreateMediaDisabled,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const smallIcons = useMemo(
    () => [
      <GoogleSlidesSmallIcon width={20} height={20} />,
      <YoutubeSmallIcon width={20} height={20} />,
      <FacebookSmallIcon width={20} height={20} />,
      <TikTokSmallIcon width={20} height={20} />,
      <PowerpointSmallIcon width={20} height={20} />,
      <InstagramSmallIcon width={20} height={20} color={theme.colors.lightGray39} />,
    ],
    [theme.colors.lightGray39],
  );

  const renderCreateMediaIcon = useCallback(() => <CreateMediaIcon />, []);
  const renderGalleryIcon = useCallback(() => <GalleryIcon />, []);
  const renderImportMediaIcon = useCallback(() => <ImportMediaIcon />, []);
  const renderBitesMediaIcon = useCallback(() => <BitesMediaIcon />, []);

  return (
    <>
      {onCreateMediaPress && (
        <ShadowedButton
          disabled={isCreateMediaDisabled}
          renderIcon={renderCreateMediaIcon}
          title={createMediaTitle}
          description={createMediaDescription}
          onPress={onCreateMediaPress}
          style={S.FirstButtonContainerStyles}
          withBeta={isWeb}
          // @ts-ignore
          dataSet={videoBtnDataSet}
        />
      )}
      {isWeb && typeof onUploadPress === 'function' && (
        <ShadowedButton
          disabled={isCreateMediaDisabled}
          renderIcon={renderGalleryIcon}
          title={t('tellYourStory.upload')}
          description={t('tellYourStory.uploadDescription')}
          onPress={onUploadPress}
          style={!onCreateMediaPress ? S.FirstButtonContainerStyles : undefined}
          // @ts-ignore
          dataSet={videoBtnDataSet}
        />
      )}

      {!isWeb && typeof onOpenFromGalleryPress === 'function' && (
        <ShadowedButton
          disabled={isCreateMediaDisabled}
          renderIcon={renderGalleryIcon}
          title={t('tellYourStory.gallery')}
          description={t('tellYourStory.galleryDescription')}
          onPress={onOpenFromGalleryPress}
          // @ts-ignore
          dataSet={galleryBtnDataSet}
        />
      )}

      <ShadowedButton
        renderIcon={renderImportMediaIcon}
        title={t('tellYourStory.import')}
        description={t('tellYourStory.importDescription')}
        onPress={onImportMediaPress}
        smallIcons={smallIcons}
        // @ts-ignore
        dataSet={importBtnDataSet}
      />

      {typeof onWorkspaceVideosPress === 'function' && (
        <ShadowedButton
          comingOn={2023}
          disabled={typeof onWorkspaceVideosPress !== 'function'}
          renderIcon={renderBitesMediaIcon}
          title={t('tellYourStory.workspaceVideos')}
          description={t('tellYourStory.workspaceVideosDescription')}
          onPress={onWorkspaceVideosPress}
        />
      )}

      {(!isWeb || onCreateMediaPress) && (
        <S.LineButton disabled={isDraftDisabled} onPress={onDraftPress}>
          <S.LineButtonText>{isWeb ? 'Video drafts' : t('tellYourStory.draft')}</S.LineButtonText>
        </S.LineButton>
      )}
    </>
  );
};

const S = {
  FirstButtonContainerStyles: {
    marginTop: calcHeight(24),
  },

  LineButton: styled.TouchableOpacity`
    margin-top: ${calcHeight(11)}px;
    margin-bottom: ${calcHeight(11)}px;
    align-self: center;
  `,
  LineButtonText: styled.Text`
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.gray11};
  `,
};

export default CreateIntroButtons;
