import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { useTheme } from 'styled-components/native';
import SpeechButtonIcon from '../../assets/icons/ai-button-speech.svg';
import SpeechButtonOnIcon from '../../assets/icons/ai-button-speech-on.svg';
import SubtitlesButtonIcon from '../../assets/icons/ai-button-subtitles.svg';
import SubtitlesButtonOnIcon from '../../assets/icons/ai-button-subtitles-on.svg';
import BackIcon from '../../assets/icons/creationFlow/back.svg';
import GlobeIcon from '../../assets/icons/globe.svg';
import ArrowCollapse from '../../assets/icons/arrow-collapse.svg';

import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { setBiteAvaitingEnhancements, trackEvent } from '../../store/appActivity/appActivity.slice';
import { calcWidth, calcHeight } from '../../utils/dimensions';
import { isWeb } from '../../utils/dimensions';
import { deviceWidth, deviceHeight } from '../../utils/dimensions';
import { IS_IOS } from '../../utils/constants/env';

import { IVideoDetails, MediaViewerProps, IMediaViewerMethods } from '../../types/media';
import VideoPlayer from '../shared/MediaViewer/VideoPlayers/VimeoVideoPlayer';
import { ANIMATION_TIME } from './components/SubtitlesPanel/common/Panel';
import SubtitlesPanel, { ISubtitlesMethods } from './components/SubtitlesPanel';
import { IEnhancement, EUploadStatus, EEnhanceType, IIntroEnhancementsChanges } from '../../types/bite';
import useVimeoLink from '../../hooks/useVimeoLink';
import { selectLocale, setIntroEnhancementsChanges } from '../../store/createBite/createBites.actions';
import {
  activeSubtitlesSelector,
  introEnhancementsSelector,
  defaultSubtitlesSelector,
  introSubtitlesSelector,
  changedSubtitlesSelector,
  introEnhancementsChangesSelector,
  introMediaProcessingStatusSelector,
  selectBiteIntroMedia,
  taskIdSelector,
} from '../../store/createBite/createBite.selectors';
import { biteSelector } from '../../store/bite/bite.selectors';
import useKeyboardHeight from '../../hooks/useKeyboardHeight';
import { useNavigation } from '@react-navigation/native';
import GenericButton from '../shared/buttons/GenericButton';
import IntroEnhancementAnimation from './components/IntroEnhancementAnimation';
import { removeFromDrafts } from '../../store/drafts/drafts.slice';
import { Keyboard, StatusBar } from 'react-native';
import { ILine, ISubtitles } from '../../types/subtitles';
import useIntroSubtitles from '../../hooks/useIntroSubtitles';
import SettingButton from './components/SettingButton';
import CreationAnimationScreen from '../shared/CreationAnimationScreen';
import LanguageSelectModal from './components/LanguageSelectModal/LanguageSelectModal';
import Toast from 'react-native-toast-message';
import { EToastTypes } from '../../utils/constants/toastConfig';
import { useIsMounted } from '../../hooks/useIsMounted';
import { EIntroMediaProcessingStatus } from '../../store/createBite/createBite.types';
import { aiEnhancementsContinueBtnDataSet } from './introEnhancements.constants';
import { StackNavigationProp } from '@react-navigation/stack';

interface IProps {
  isEdit?: boolean;
  useVimeo?: boolean;
  isPaused?: undefined | boolean;
  onNext?: (IIntroEnhancementsChanges) => void;
}

interface IState {
  isActive: boolean;
  subtitlesMethods: null | ISubtitlesMethods;
  videoPlayerMethods: null | IMediaViewerMethods;
  animatingPanelTimeout: null | number;
  prevVideoTime: number;
  setCurrentLineNum: null | string;
}

const IntroEnhancements: React.FC<IProps> = ({ isEdit, useVimeo = false, isPaused: isPausedFromParent, onNext }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigation = useNavigation<StackNavigationProp<any>>();
  const dispatch = useDispatch();
  const insets = useSafeAreaInsets();
  const { keyboardHeight } = useKeyboardHeight();
  const isFocused = useIsFocused();
  const animationTimeout = useRef(null);
  const isMountedRef = useIsMounted();

  const { selectedBite } = useSelector(biteSelector);
  const enhancements = useSelector(introEnhancementsSelector);
  const introMediaProcessingStatus = useSelector(introMediaProcessingStatusSelector);
  const introMedia = useSelector(selectBiteIntroMedia);

  const [isStarsAnimationRunning, setIsStarsAnimationRunning] = useState(true);
  // const [isSubtitlePanelOpen, setIsSubtitlePanelOpen] = useState(false);
  const [isLanguageSelectOpen, setIsLanguageSelectOpen] = useState(false);
  // const [isSpeechMagicPanelOpen, setIsSpeechMagicPanelOpen] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  // const [isPausedByPanel, setIsPausedByPanel] = useState(false);

  const doneEnhancements = useMemo(
    () => enhancements?.filter(({ upload_status }) => upload_status === EUploadStatus.done),
    [enhancements],
  );

  // const closeAllPanels = useCallback(() => {
  //   setIsSpeechMagicPanelOpen(false);
  //   setIsSubtitlePanelOpen(false);
  // }, []);

  // const togglePanelCallback = useCallback(
  //   (prevPanelState: boolean) => {
  //     const isNeedToPauseByPanel = !prevPanelState && !isPaused;
  //     const isNeedToPlayByPanel = !isNeedToPauseByPanel && isPausedByPanel;
  //
  //     if (isNeedToPauseByPanel) {
  //       state.current.videoPlayerMethods?.pause();
  //       setIsPausedByPanel(true);
  //     }
  //
  //     if (isNeedToPlayByPanel) {
  //       state.current.videoPlayerMethods?.play();
  //       setIsPausedByPanel(false);
  //     }
  //
  //     return !prevPanelState;
  //   },
  //   [isPaused, isPausedByPanel],
  // );

  // const toggleSubtitlePanel = useCallback(() => {
  //   setIsSpeechMagicPanelOpen(false);
  //   setIsSubtitlePanelOpen(togglePanelCallback);
  // }, [togglePanelCallback]);
  //
  // const toggleSpeechMagicPanel = useCallback(() => {
  //   setIsSubtitlePanelOpen(false);
  //   setIsSpeechMagicPanelOpen(togglePanelCallback);
  // }, [togglePanelCallback]);

  const runStarsAnimation = useCallback(() => {
    clearTimeout(animationTimeout.current);
    setIsStarsAnimationRunning(true);
    animationTimeout.current = setTimeout(() => {
      setIsStarsAnimationRunning(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (isFocused) {
      runStarsAnimation();
      StatusBar.setBarStyle('light-content');
      return;
    }

    if (IS_IOS) {
      StatusBar.setBarStyle('dark-content');
    }
  }, [isFocused, runStarsAnimation]);

  useEffect(() => {
    return () => {
      clearTimeout(animationTimeout.current);
    };
  }, []);

  const media = useMemo(() => {
    return doneEnhancements?.reduce<{
      mediaOriginal?: IEnhancement;
      mediaEnhanced?: IEnhancement;
    }>((map, currentMedia) => {
      if (currentMedia.enhance_type === EEnhanceType.original) {
        map.mediaOriginal = currentMedia;
        return map;
      }
      map.mediaEnhanced = currentMedia;
      return map;
    }, {});
  }, [doneEnhancements]);

  const enabledEnhancement = useMemo(
    () => doneEnhancements?.find((enhancement) => enhancement.enabled),
    [doneEnhancements],
  );
  const currentTaskId = useSelector(taskIdSelector);
  const taskId = enabledEnhancement?.task_id || currentTaskId;

  const subtitles = useSelector(introSubtitlesSelector);
  const activeSubtitles = useSelector(activeSubtitlesSelector);
  const changedSubtitles = useSelector(changedSubtitlesSelector);
  const defaultSubtitles = useSelector(defaultSubtitlesSelector);
  const introEnhancementsChanges = useSelector(introEnhancementsChangesSelector);
  const subtitlesToDisplay = introEnhancementsChanges.subtitlesEnabledState
    ? changedSubtitles
    : activeSubtitles || defaultSubtitles;

  const [subtitlesEnabled, setSubtitlesEnabled] = useState(!!activeSubtitles);
  // undefined as default, null is used for original subtitles where locale is not known
  const [selectedLocale, setSelectedLocale] = useState<ISubtitles['locale']>(subtitlesToDisplay?.locale);
  const [enhancementsEnabled, setEnhancementsEnabled] = useState(!!media?.mediaEnhanced?.enabled);
  const [currentLine, setCurrentLine] = useState<ILine>(null);

  const { initialLinesData, linesData, setLines } = useIntroSubtitles(subtitlesToDisplay);

  const vimeoOriginal = useVimeoLink(useVimeo, media?.mediaOriginal?.media_url);
  const vimeoEnhanced = useVimeoLink(useVimeo, media?.mediaEnhanced?.media_url);

  const state = useRef<IState>({
    isActive: true,
    subtitlesMethods: null,
    videoPlayerMethods: null,
    animatingPanelTimeout: null,
    prevVideoTime: 0,
    setCurrentLineNum: null,
  });

  const hasAvailableSubtitles = useMemo(
    () => !!subtitles?.some(({ upload_status }) => upload_status === EUploadStatus.done),
    [subtitles],
  );

  const enhancementsAvailable = useMemo(
    () => media?.mediaEnhanced?.upload_status === EUploadStatus.done,
    [media?.mediaEnhanced],
  );

  useFocusEffect(
    useCallback(() => {
      dispatch(
        trackEvent({
          event: 'enhancements_screen',
          props: {
            enhancements_available: enhancementsAvailable,
            subtitle_available: hasAvailableSubtitles,
            subtitles_enabled: subtitlesEnabled,
            enhancements_enabled: enhancementsEnabled,
          },
        }),
      );
    }, [dispatch, enhancementsAvailable, hasAvailableSubtitles, subtitlesEnabled, enhancementsEnabled]),
  );

  useEffect(() => {
    if (
      // initial case
      selectedLocale !== undefined &&
      // define locale case
      !(selectedLocale === null && subtitlesToDisplay?.locale)
    ) {
      return;
    }

    setSelectedLocale(subtitlesToDisplay?.locale);
    setSubtitlesEnabled(introEnhancementsChanges.subtitlesEnabledState?.enabled ?? !!activeSubtitles);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubtitles, selectedLocale, subtitlesToDisplay?.locale]);

  useEffect(() => {
    setEnhancementsEnabled(!!media?.mediaEnhanced?.enabled);
  }, [media?.mediaEnhanced]);

  const mediaURI = useMemo(() => {
    if (!enhancements) {
      // display from local
      return introMedia?.media_url || null;
    }

    if (enhancementsEnabled) {
      return useVimeo ? vimeoEnhanced.vimeoVideoUri : media?.mediaEnhanced?.s3_video_url;
    }

    const originalVideoUrl = useVimeo ? vimeoOriginal.vimeoVideoUri : media?.mediaOriginal?.s3_video_url;

    // if original is still processing s3_video_url will be empty string
    return originalVideoUrl || introMedia?.media_url || null;
  }, [
    enhancements,
    enhancementsEnabled,
    useVimeo,
    vimeoOriginal.vimeoVideoUri,
    media?.mediaOriginal?.s3_video_url,
    media?.mediaEnhanced?.s3_video_url,
    introMedia?.media_url,
    vimeoEnhanced.vimeoVideoUri,
  ]);

  const toggleSubtitles = useCallback(() => {
    setSubtitlesEnabled(!subtitlesEnabled);
    dispatch(
      trackEvent({
        event: 'toggle_subtitles',
        props: {
          is_on: !subtitlesEnabled,
        },
      }),
    );
  }, [dispatch, subtitlesEnabled]);

  const toggleEnhancements = useCallback(() => {
    if (!enhancementsEnabled) {
      runStarsAnimation();
    }
    setEnhancementsEnabled(!enhancementsEnabled);
    dispatch(
      trackEvent({
        event: 'toggle_enhancements',
        props: {
          is_on: !enhancementsEnabled,
        },
      }),
    );
  }, [enhancementsEnabled, dispatch, runStarsAnimation]);

  const [isShowSubtitlesPanel, setIsShowSubtitlesPanel] = useState(false);

  const showSubtitlesPanel = useCallback(() => {
    if (isShowSubtitlesPanel || !hasAvailableSubtitles) {
      setIsShowSubtitlesPanel(false);
      Keyboard.dismiss();
      return;
    }

    // @ts-ignore
    state.current.animatingPanelTimeout = setTimeout(() => {
      if (!state.current.isActive) {
        return;
      }
      setIsShowSubtitlesPanel(true);
    }, ANIMATION_TIME + 300);
  }, [hasAvailableSubtitles, isShowSubtitlesPanel]);

  const handleScrollSubtitles = useCallback(() => {
    state.current.videoPlayerMethods?.pause();
  }, []);

  const handleSetCurrentLine = useCallback((line) => {
    setCurrentLine(line);
    state.current.setCurrentLineNum = line.num;
    state.current.videoPlayerMethods?.pause();
    setTimeout(() => {
      if (state.current.isActive) {
        state.current.videoPlayerMethods?.seek(line.startTime);
      }
    }, 100);
  }, []);

  const changes = useMemo((): IIntroEnhancementsChanges => {
    const result: IIntroEnhancementsChanges = {};

    if (
      // logic wise not expected, but
      // in some cases selectedLocale might be undefined
      // when the screen is opened by url (without data)
      // not handling this leads to a rendering loop
      // probably a matter for deeper fix
      selectedLocale !== undefined &&
      (subtitlesEnabled !== !!activeSubtitles || selectedLocale !== (activeSubtitles || defaultSubtitles)?.locale)
    ) {
      result.subtitlesEnabledState = {
        locale: selectedLocale,
        enabled: subtitlesEnabled,
      };
    }

    if (media?.mediaEnhanced && media?.mediaEnhanced?.enabled !== enhancementsEnabled) {
      result.mediaId = enhancementsEnabled ? media?.mediaEnhanced?.id : media?.mediaOriginal?.id;
    }

    return result;
  }, [
    subtitlesEnabled,
    activeSubtitles,
    selectedLocale,
    defaultSubtitles,
    media?.mediaEnhanced,
    media?.mediaOriginal,
    enhancementsEnabled,
  ]);

  const isReadyToSave = !subtitlesEnabled || !changes.subtitlesEnabledState || !!subtitlesToDisplay;

  const [isPausedGoNext, setIsPausedGoNext] = useState<boolean>(undefined);
  const handleOnNext = useCallback(() => {
    state.current.videoPlayerMethods?.pause();
    setIsPausedGoNext(true);
  }, []);

  useEffect(() => {
    if (isPausedGoNext) {
      setIsPausedGoNext(undefined);

      if (onNext) {
        onNext(changes);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPausedGoNext]);

  useEffect(() => {
    if (keyboardHeight) {
      state.current.videoPlayerMethods?.pause();
    }
  }, [keyboardHeight]);

  const handleOnProgress = useCallback(
    ({ currentTime }) => {
      if (!linesData) {
        return;
      }
      currentTime = Math.round(currentTime * 10) / 10;

      if (currentTime === state.current.prevVideoTime || keyboardHeight) {
        return;
      }
      state.current.prevVideoTime = currentTime;
      const lineIndex = linesData.findIndex(({ startTime, endTime }) => {
        return currentTime >= startTime && currentTime < endTime;
      });
      const line = linesData[lineIndex];
      if (!line) {
        return;
      }
      if (state.current.setCurrentLineNum !== null && state.current.setCurrentLineNum !== line.num) {
        return;
      }
      state.current.setCurrentLineNum = null;

      setCurrentLine(line);
      state.current.subtitlesMethods?.scrollTo(line.num);
    },
    [linesData, keyboardHeight],
  );

  const setVideoPlayerMethods = useCallback((methods) => {
    state.current.videoPlayerMethods = methods;
  }, []);
  const [videoDetails, setVideoDetails] = useState<IVideoDetails>(null);
  const setSubtitlesMethods = useCallback((methods) => {
    state.current.subtitlesMethods = methods;
  }, []);

  const onEndVideo = useCallback(() => {
    setIsPaused(true);
    state.current.videoPlayerMethods?.pause();
    setTimeout(() => {
      if (state.current.isActive) {
        state.current.videoPlayerMethods?.seek(0);
      }
    }, 100);
  }, []);

  const handlePlay = useCallback(() => {
    // closeAllPanels();
    setIsPaused(false);
  }, []);

  const handlePause = useCallback(() => {
    // setIsPausedByPanel(false);
    setIsPaused(true);
  }, []);

  const mediaViewerProps: MediaViewerProps = useMemo(
    () => ({
      mediaURI,
      lines: isShowSubtitlesPanel || subtitlesEnabled ? linesData : undefined,
      disableVolume: true,
      onProgress: handleOnProgress,
      onMethods: setVideoPlayerMethods,
      onMediaDetails: setVideoDetails,
      disablePlayPause: true,
      disableFullscreen: true,
      disableTimer: true,
      disableSeekbar: true,
      isPausedInternal: isPaused,
      tapAnywhereToPause: true,
      onEnd: onEndVideo,
      withPlayButton: true,
      onPlay: handlePlay,
      onPause: handlePause,
      activeLocale: subtitlesToDisplay?.locale,
    }),
    [
      mediaURI,
      isShowSubtitlesPanel,
      subtitlesEnabled,
      linesData,
      handleOnProgress,
      setVideoPlayerMethods,
      isPaused,
      onEndVideo,
      handlePlay,
      handlePause,
      subtitlesToDisplay?.locale,
    ],
  );

  useEffect(() => {
    if (isPausedFromParent || isPausedGoNext) {
      state.current.videoPlayerMethods?.pause();
    }
  }, [isPausedFromParent, isPausedGoNext]);

  useEffect(() => {
    dispatch(setIntroEnhancementsChanges(changes));
  }, [changes, dispatch]);

  useFocusEffect(
    useCallback(() => {
      if (selectedBite) {
        dispatch(
          setBiteAvaitingEnhancements({
            biteId: selectedBite.id,
            isAvaitingEnhancements: false,
          }),
        );
        dispatch(removeFromDrafts(selectedBite.id));
      }
    }, [dispatch, selectedBite]),
  );

  useEffect(() => {
    state.current.isActive = true;
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      state.current.isActive = false;
    };
  }, []);

  const [containerHeight, setContainerHeight] = useState(deviceHeight);
  const onLayout = useCallback((e) => {
    setContainerHeight(e.nativeEvent.layout.height);
  }, []);

  const onBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const { videoWidth, videoHeight } = useMemo(() => {
    const maxHeight = isShowSubtitlesPanel
      ? containerHeight - (IS_IOS ? calcHeight(480) : calcHeight(420)) - (!isWeb ? insets.bottom : 0)
      : deviceHeight;

    const maxWidth = isShowSubtitlesPanel ? deviceWidth - (IS_IOS ? 80 : 32) : deviceWidth;

    const aspectRatio = (videoDetails?.width || 4) / (videoDetails?.height || 5);

    if (maxHeight - maxWidth / aspectRatio >= 0) {
      return {
        videoWidth: maxWidth,
        videoHeight: Math.round(maxWidth / aspectRatio),
      };
    }

    return {
      videoWidth: Math.round(maxHeight * aspectRatio),
      videoHeight: maxHeight,
    };
  }, [isShowSubtitlesPanel, containerHeight, insets.bottom, videoDetails?.width, videoDetails?.height]);

  const overlayBottomOffset = useMemo(() => {
    const bottomPanelHeight = calcHeight(120);
    const buttonsHeight = calcHeight(150);
    const videoBottomOffset = (deviceHeight - bottomPanelHeight) / 2 - videoHeight / 2;

    if (videoBottomOffset > buttonsHeight) {
      return videoBottomOffset - buttonsHeight + calcHeight(170);
    }

    return calcHeight(170);
  }, [videoHeight]);

  const toggleIsLanguageSelectOpen = useCallback(() => {
    setIsLanguageSelectOpen((prev) => !prev);
  }, []);

  const handleLocaleSelect = useCallback(
    ({ locale }) => {
      setSelectedLocale(locale);

      dispatch(
        selectLocale({
          taskId,
          locale,
          sourceLocale: subtitlesToDisplay?.locale || activeSubtitles?.locale || defaultSubtitles?.locale,
          onError: () => {
            if (!isMountedRef.current) {
              return;
            }
            Toast.show({
              type: EToastTypes.networkError,
              topOffset: 0,
            });
            const localeToSet = activeSubtitles?.locale || defaultSubtitles?.locale;
            setSelectedLocale(localeToSet);
          },
        }),
      );

      toggleIsLanguageSelectOpen();
    },
    [
      dispatch,
      taskId,
      subtitlesToDisplay?.locale,
      activeSubtitles?.locale,
      defaultSubtitles?.locale,
      toggleIsLanguageSelectOpen,
      isMountedRef,
    ],
  );

  const renderSubtitlesIcon = useCallback(() => {
    if (subtitlesEnabled) {
      return <SubtitlesButtonOnIcon />;
    }
    return <SubtitlesButtonIcon />;
  }, [subtitlesEnabled]);

  const renderSpeechIcon = useCallback(() => {
    if (enhancementsEnabled) {
      return <SpeechButtonOnIcon />;
    }
    return <SpeechButtonIcon />;
  }, [enhancementsEnabled]);

  const selectedLocaleText = useMemo(() => {
    if (selectedLocale) {
      return t(`locales.${selectedLocale}`);
    }
    return t('bitesMagic.originalLocale');
  }, [selectedLocale, t]);

  const subtitlesProcessing =
    introMediaProcessingStatus.all === EIntroMediaProcessingStatus.PROCESSING &&
    (introMediaProcessingStatus.subtitles === EIntroMediaProcessingStatus.PROCESSING ||
      introMediaProcessingStatus.subtitles === EIntroMediaProcessingStatus.INACTIVE);

  const enhancementsProcessing =
    introMediaProcessingStatus.all === EIntroMediaProcessingStatus.PROCESSING &&
    (introMediaProcessingStatus.enhancements === EIntroMediaProcessingStatus.PROCESSING ||
      introMediaProcessingStatus.enhancements === EIntroMediaProcessingStatus.INACTIVE);

  return (
    <S.Container onLayout={onLayout}>
      {isStarsAnimationRunning && (
        <S.AnimationContainer pointerEvents='none'>
          <IntroEnhancementAnimation />
        </S.AnimationContainer>
      )}
      <S.VideoContainer>
        <S.TopMenu>
          {isShowSubtitlesPanel ? (
            <>
              <S.SelectedLocale onPress={toggleIsLanguageSelectOpen}>
                <GlobeIcon width={calcWidth(16)} height={calcHeight(16)} color={theme.colors.borderGray} />
                <S.SelectedLocaleText>{selectedLocaleText}</S.SelectedLocaleText>
                <S.ArrowContainer>
                  <ArrowCollapse
                    width={calcWidth(9)}
                    height={calcHeight(9)}
                    rotation={180}
                    color={theme.colors.borderGray}
                  />
                </S.ArrowContainer>
              </S.SelectedLocale>
              <S.DoneButton onPress={showSubtitlesPanel} isSmall content={t('bitesMagic.buttons.done')} />
            </>
          ) : (
            <>
              <S.BackButtonWrapper>
                <S.BackButton onPress={onBack}>
                  <BackIcon fill={theme.colors.white} />
                </S.BackButton>
              </S.BackButtonWrapper>
              <S.EnhancementLogo>
                <S.EnhancementLogoText>Bites.ai Magic</S.EnhancementLogoText>
              </S.EnhancementLogo>
              <S.DoneButtonWrapper>
                <S.DoneButton
                  isLoading={!isReadyToSave}
                  onPress={handleOnNext}
                  isSmall
                  content={isEdit ? t('common.Save') : t('common.Continue')}
                  // @ts-ignore
                  dataSet={aiEnhancementsContinueBtnDataSet}
                />
              </S.DoneButtonWrapper>
            </>
          )}
        </S.TopMenu>
        {mediaURI && (
          <S.PlayerWrapper>
            <S.PlayerContainer isMinified={isShowSubtitlesPanel} videoWidth={videoWidth} videoHeight={videoHeight}>
              <VideoPlayer mediaViewerProps={mediaViewerProps} />
            </S.PlayerContainer>
          </S.PlayerWrapper>
        )}
        {!isShowSubtitlesPanel && (
          <S.Overlay pointerEvents={'box-none'}>
            <S.OverlayButtons bottomOffset={overlayBottomOffset}>
              <SettingButton
                disabled={subtitlesProcessing || !hasAvailableSubtitles}
                onEdit={showSubtitlesPanel}
                onSwitchPress={toggleSubtitles}
                title={t('bitesMagic.buttons.subtitles')}
                disabledDescription={t('bitesMagic.buttons.unavailableInThisLanguage')}
                enabled={subtitlesEnabled}
                renderIcon={renderSubtitlesIcon}
                subtitle={selectedLocaleText}
                isLoading={subtitlesProcessing}
              />
              <SettingButton
                disabled={enhancementsProcessing || !enhancementsAvailable}
                onSwitchPress={toggleEnhancements}
                title={t('bitesMagic.buttons.speechMagic')}
                disabledDescription={t('bitesMagic.buttons.noEnhancementsMade')}
                enabled={enhancementsEnabled}
                renderIcon={renderSpeechIcon}
                isLoading={enhancementsProcessing}
              />
            </S.OverlayButtons>
          </S.Overlay>
        )}
      </S.VideoContainer>

      <SubtitlesPanel
        subtitlesToDisplay={subtitlesToDisplay}
        isVisible={isShowSubtitlesPanel}
        onClose={showSubtitlesPanel}
        lines={linesData}
        linesOriginal={initialLinesData}
        currentLine={currentLine}
        onScroll={handleScrollSubtitles}
        onSetCurrentLine={handleSetCurrentLine}
        onChangeLine={setLines}
        onMethods={setSubtitlesMethods}
      />

      <LanguageSelectModal
        isVisible={isLanguageSelectOpen}
        onLocaleSelect={handleLocaleSelect}
        onClose={toggleIsLanguageSelectOpen}
      />
    </S.Container>
  );
};

const S = {
  AnimationContainer: styled.View`
    flex: 1;
    align-items: center;
    align-self: center;
    z-index: 2;
    position: absolute;
    top: ${isWeb ? calcHeight(-200) : calcHeight(-100)}px;
  `,
  TopMenu: styled.View`
    position: absolute;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    top: ${calcHeight(40)}px;
    padding: ${calcWidth(15)}px;
  `,
  BackButtonWrapper: styled.View`
    flex: 1;
  `,
  BackButton: styled.TouchableOpacity`
    width: ${calcWidth(40)}px;
    height: ${calcWidth(40)}px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: ${calcWidth(40 / 2)};
    z-index: 2;
    align-items: center;
    justify-content: center;
  `,
  OverlayButtons: styled.View<{ bottomOffset: number }>`
    position: absolute;
    left: ${calcWidth(25)}px;
    bottom: ${({ bottomOffset }) => calcHeight(bottomOffset)}px;
  `,
  Overlay: styled.View`
    position: absolute;
    width: 100%;
    height: 100%;
    flex: 1;
  `,
  DoneButtonWrapper: styled.View`
    flex: 1;
    align-items: flex-end;
  `,
  DoneButton: styled(GenericButton)`
    width: ${calcWidth(78)}px;
    height: ${calcHeight(40)}px;
    padding: 0;
  `,
  Container: styled.View`
    position: relative;
    flex: 1;
    height: ${deviceHeight}px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.darkGray10};
  `,
  VideoContainer: styled.View`
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    background-color: black;
    overflow: hidden;
  `,
  Text: styled.Text`
    margin: ${calcHeight(16)}px;
    width: 100%;
    font-size: ${({ theme }) => theme.fontSizes.s28};
  `,
  PlayerWrapper: styled.View`
    z-index: -1;
    flex: 1;
    align-self: center;
    align-items: center;
    justify-content: center;
  `,
  PlayerContainer: styled.View<{
    isMinified: boolean;
    videoWidth: number;
    videoHeight: number;
  }>`
    width: ${({ videoWidth, videoHeight }) =>
      isWeb
        ? calcWidth((videoWidth / videoHeight) * (videoHeight - 115))
        : calcWidth((videoWidth / videoHeight) * (deviceHeight - 155))}px;
    max-width: ${({ videoWidth, videoHeight }) =>
      isWeb
        ? calcWidth((videoWidth / videoHeight) * (videoHeight - 115))
        : calcWidth((videoWidth / videoHeight) * (deviceHeight - 155))}px;
    height: ${({ videoHeight }) => calcHeight(videoHeight)}px;
    max-height: ${({ videoHeight }) => calcHeight(videoHeight)}px;
    align-self: center;
    justify-content: center;
    border-radius: ${({ isMinified }) => (isMinified ? calcHeight(20) : 0)}px;
    margin-top: ${({ isMinified, videoHeight }) =>
      isMinified || videoHeight < deviceHeight - calcHeight(200) ? calcHeight(-150) : 0}px;
    overflow: hidden;
  `,
  EmptyView: styled.View`
    flex: 1;
  `,
  EnhancementLogo: styled.View`
    flex: 1;
    align-items: center;
    justify-content: center;
  `,
  EnhancementLogoText: styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSizes.s14};
  `,
  CreationAnimationScreen: styled(CreationAnimationScreen)`
    position: absolute;
    background: white;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
  `,
  SelectedLocale: styled.TouchableOpacity`
    flex-direction: row;
    align-items: center;
    z-index: 1;
  `,
  SelectedLocaleText: styled.Text`
    margin: 0 ${calcWidth(5)}px;
    color: ${({ theme }) => theme.colors.lightGray38};
    font-size: ${({ theme }) => theme.fontSizes.s13};
  `,
  ArrowContainer: styled.View`
    width: ${calcWidth(9)}px;
    height: ${calcWidth(9)}px;
    transform: rotate(180deg);
  `,
};

export default IntroEnhancements;
