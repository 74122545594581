import Types, { EIntroMediaProcessingStatus } from './createBite.types';
import BiteTypes from '../bite/bite.types';
import AuthTypes from '../auth/auth.types';
import { IBiteSettings, IIntroMediaProcessingStatus } from './createBite.types';
import { IIcon } from '../icons/icons.types';
import { EFileType, IMedia } from '../../types/media';
import { IBiteItem, IEnhancement, IIntroEnhancementsChanges } from '../../types/bite';
import { cloneDeep } from 'lodash';
import { ISubtitles, ISubtitlesLines } from '../../types/subtitles';
import { IQuestionSuggestion } from './createBites.actions';

export interface ICreateBiteState {
  biteName: string;
  isNameSuggestion: boolean;
  introMedia: null | IMedia;
  introMediaProcessingStatus: IIntroMediaProcessingStatus;
  introMediaDuration?: number;
  cover: null | number;
  cover_url: null | string;
  linked_cover_url: string;
  coverKeywordSuggestion: string;
  biteIcon: IIcon | null;
  description: string;
  biteSettings: IBiteSettings;
  isUploading: boolean;
  didUpload: boolean;
  hasUploadingError: boolean;
  isEditMode: boolean;
  editedSections: {
    intro: boolean;
    summary: boolean;
    question: boolean;
    introEnhancements: boolean;
  };
  createdBite: IBiteItem | null;
  taskId: null | string;
  questionTaskId: null | string;
  introEnhancements: null | IEnhancement[];
  introSubtitles: null | ISubtitles[];
  fetchingIntroSubtitlesLines: { [key: string]: boolean };
  introSubtitlesLines: null | ISubtitlesLines;
  introEnhancementsChanges: IIntroEnhancementsChanges;
  summarySuggestionCards: string[];
  questionSuggestion: IQuestionSuggestion;
}

const introMediaProcessingStatusInitial = {
  counter: 1,
  biteId: null,
  all: EIntroMediaProcessingStatus.INACTIVE,
  audioExport: EIntroMediaProcessingStatus.INACTIVE,
  videoExport: EIntroMediaProcessingStatus.INACTIVE,
  s3: EIntroMediaProcessingStatus.INACTIVE,
  original: EIntroMediaProcessingStatus.INACTIVE,
  enhancements: EIntroMediaProcessingStatus.INACTIVE,
  subtitles: EIntroMediaProcessingStatus.INACTIVE,
  summarySuggestion: EIntroMediaProcessingStatus.INACTIVE,
  questionSuggestion: EIntroMediaProcessingStatus.INACTIVE,
  biteNameSuggestion: EIntroMediaProcessingStatus.INACTIVE,
  coverSuggestion: EIntroMediaProcessingStatus.INACTIVE,
  youtube: EIntroMediaProcessingStatus.INACTIVE,
  image: EIntroMediaProcessingStatus.INACTIVE,
} as IIntroMediaProcessingStatus;

const initialBiteSettings: IBiteSettings = {
  is_private: false,
  discussion_enabled: true,
  skip_able: true,
};

export const initialState: ICreateBiteState = {
  biteName: '',
  isNameSuggestion: false,
  introMedia: null,
  introMediaProcessingStatus: introMediaProcessingStatusInitial,
  cover: null,
  cover_url: null,
  linked_cover_url: '',
  coverKeywordSuggestion: null,
  biteIcon: null,
  description: '',
  biteSettings: initialBiteSettings,
  isUploading: false,
  didUpload: false,
  hasUploadingError: false,
  isEditMode: false,
  editedSections: {
    intro: false,
    summary: false,
    question: false,
    introEnhancements: false,
  },
  createdBite: null,
  taskId: null,
  questionTaskId: null,
  introEnhancements: null,
  introSubtitles: null,
  fetchingIntroSubtitlesLines: {},
  introSubtitlesLines: null,
  introEnhancementsChanges: {},
  summarySuggestionCards: null,
  questionSuggestion: null,
};

const createBiteReducer = (state = initialState, action: any): ICreateBiteState => {
  switch (action.type) {
    case Types.SET_BITE_COVER:
      return {
        ...state,
        cover: action.payload.coverId,
        cover_url: action.payload.coverUrl,
        linked_cover_url: action.payload.coverUrl,
        coverKeywordSuggestion: null,
      };

    case Types.SET_BITE_COVER_FROM_URL: {
      return {
        ...state,
        cover: null,
        cover_url: null,
        linked_cover_url: action.payload,
        coverKeywordSuggestion: null,
      };
    }

    case Types.CLEAR_BITE_COVER: {
      return {
        ...state,
        cover: null,
        cover_url: null,
        linked_cover_url: '',
      };
    }

    case Types.CLEAR_BITE_MEDIA: {
      return {
        ...state,
        introMedia: null,
        introMediaProcessingStatus: introMediaProcessingStatusInitial,
      };
    }

    case Types.TOGGLE_SETTING_ITEM:
      const biteSettings = { ...state.biteSettings };
      biteSettings[action.payload as keyof IBiteSettings] = !biteSettings[action.payload as keyof IBiteSettings];
      return {
        ...state,
        biteSettings,
      };
    case Types.UPDATE_SETTINGS:
      return {
        ...state,
        biteSettings: { ...state.biteSettings, ...action.payload },
      };

    case Types.SET_BITE_ICON:
      return {
        ...state,
        biteIcon: action.payload,
      };

    case Types.SET_BITE_NAME:
      return {
        ...state,
        biteName: action.payload,
        isNameSuggestion: false,
      };

    case Types.IS_NAME_SUGGESTION_APPLIED:
      return {
        ...state,
        isNameSuggestion: action.payload,
      };

    case Types.SET_COVER_KEYWORD_SUGGESTION:
      return {
        ...state,
        coverKeywordSuggestion: action.payload,
      };

    case Types.SET_BITE_DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      };

    case BiteTypes.SET_BITE_TO_EDIT: {
      const { createBiteState } = action.payload;

      if (!createBiteState) {
        return state;
      }

      const introEnhancements = createBiteState.extend?.enhancements;
      const introSubtitles = createBiteState.extend?.subtitles;
      delete createBiteState.extend;
      return {
        ...createBiteState,
        introMedia: createBiteState.introMedia || state.introMedia,
        introEnhancements,
        introSubtitles,
        introSubtitlesLines: null,
        introMediaProcessingStatus: { ...state.introMediaProcessingStatus },
        taskId: state.taskId,
        editedSections: state.editedSections,
        introMediaDuration: state.introMediaDuration,
        summarySuggestionCards: state.summarySuggestionCards,
        questionSuggestion: state.questionSuggestion,
        isNameSuggestion: state.isNameSuggestion,
        coverKeywordSuggestion: state.coverKeywordSuggestion,
      };
    }

    case Types.SET_BITE_INTRO:
      return {
        ...state,
        introMedia: action.payload,
        introMediaProcessingStatus: {
          ...introMediaProcessingStatusInitial,
          counter: state.introMediaProcessingStatus.counter + 1,
        },
      };

    case Types.SET_BITE_INTRO_DURATION:
      return {
        ...state,
        introMediaDuration: action.payload,
      };

    case Types.SET_BITE_INTRO_EDITED:
      return {
        ...state,
        editedSections: {
          ...state.editedSections,
          intro: action.payload,
        },
      };

    case Types.SET_BITE_SUMMARY_EDITED:
      return {
        ...state,
        editedSections: {
          ...state.editedSections,
          summary: action.payload,
        },
      };

    case Types.SET_BITE_QUESTION_EDITED:
      return {
        ...state,
        editedSections: {
          ...state.editedSections,
          question: action.payload,
        },
      };

    case Types.SET_BITE_INTRO_ENHANCEMENTS_EDITED:
      return {
        ...state,
        editedSections: {
          ...state.editedSections,
          introEnhancements: action.payload,
        },
      };

    case Types.RESET_EDITED_SECTIONS:
      return {
        ...state,
        editedSections: cloneDeep(initialState.editedSections),
      };

    case Types.SET_INTRO_TASK:
      return {
        ...state,
        taskId: action.payload,
        // introMediaUploadError: false,
      };

    case Types.SET_QUESTION_TASK:
      return {
        ...state,
        questionTaskId: action.payload,
      };

    case Types.SET_BITE_INTRO_UPLOADING:
      return {
        ...state,
        taskId: null,
        introMediaProcessingStatus: {
          ...introMediaProcessingStatusInitial,
          counter: state.introMediaProcessingStatus.counter + 1,
        },
      };

    case Types.SET_INTRO_MEDIA_PROCESSING_STATUS:
      return {
        ...state,
        introMediaProcessingStatus: {
          ...state.introMediaProcessingStatus,
          ...action.payload,
        },
      };

    case Types.POST_OR_UPDATE_QUIZ_BITE_REQUEST:
      return {
        ...state,
        isUploading: true,
        hasUploadingError: false,
      };

    case Types.POST_OR_UPDATE_BITE_SUCCESS:
      return {
        ...state,
        isUploading: false,
        didUpload: true,
        createdBite: action.payload ? action.payload : null,
      };

    case Types.POST_OR_UPDATE_BITE_ERROR:
      return {
        ...state,
        isUploading: false,
        hasUploadingError: true,
      };

    case Types.CLEAR_CREATE_BITE_STATE:
      return {
        ...initialState,
        introMediaProcessingStatus: {
          ...introMediaProcessingStatusInitial,
          counter: state.introMediaProcessingStatus.counter + 1,
        },
      };

    case BiteTypes.CLEAN_EDIT_AND_CREATE_BITE_STATE:
      return initialState;

    case Types.SET_INTRO_VIDEO_MEDIA_URI: {
      return {
        ...state,
        // @ts-ignore
        introMedia: {
          isVideoPathLocal: true,
          file_type: EFileType.VIDEO,
          media_url: action.payload,
        },
      };
    }

    case Types.SET_INTRO_ENHANCEMENTS:
      return (() => {
        // merge enhancements
        // prefer media from the action payload
        const introEnhancements = [];
        const updateEnhancementsMap = {};

        action.payload.enhancements?.forEach((media) => {
          updateEnhancementsMap[media.enhance_type] = media;
          introEnhancements.push(media);
        });

        state.introEnhancements?.map((media) => {
          if (!updateEnhancementsMap[media.enhance_type]) {
            introEnhancements.push(media);
          }
        });

        return {
          ...state,
          introEnhancements,
        };
      })();

    case Types.SET_INTRO_SUBTITLES:
      return {
        ...state,
        introSubtitles: action.payload,
      };

    case Types.SET_FETCHING_INTRO_SUBTITLES_LINES:
      return (() => {
        const fetchingIntroSubtitlesLines = { ...state.fetchingIntroSubtitlesLines };

        if (action.payload.isFetching) {
          fetchingIntroSubtitlesLines[action.payload.id] = true;
        } else {
          delete fetchingIntroSubtitlesLines[action.payload.id];
        }

        return {
          ...state,
          fetchingIntroSubtitlesLines,
        };
      })();

    case Types.SET_INTRO_SUBTITLES_LINES:
      return (() => {
        const fetchingIntroSubtitlesLines = { ...state.fetchingIntroSubtitlesLines };
        delete fetchingIntroSubtitlesLines[action.payload.id];
        return {
          ...state,
          introSubtitlesLines: action.payload,
          fetchingIntroSubtitlesLines,
        };
      })();

    case Types.DEFINE_INTRO_SUBTITLES_NULL_LOCALE:
      return {
        ...state,
        introEnhancementsChanges: {
          ...state.introEnhancementsChanges,
          subtitlesEnabledState: !state.introEnhancementsChanges.subtitlesEnabledState
            ? state.introEnhancementsChanges.subtitlesEnabledState
            : {
                ...state.introEnhancementsChanges.subtitlesEnabledState,
                locale: action.payload.locale,
              },
        },
        introSubtitles: state.introSubtitles.map((subtitles) => {
          if (subtitles.task_id === action.payload.taskId && subtitles.locale === null) {
            return {
              ...subtitles,
              locale: action.payload.locale,
            };
          }

          return subtitles;
        }),
        introSubtitlesLines: {
          ...state.introSubtitlesLines,
          locale:
            state.introSubtitlesLines.task_id === action.payload.taskId && state.introSubtitlesLines.locale === null
              ? action.payload.locale
              : state.introSubtitlesLines.locale,
        },
      };

    case Types.UPDATE_INTRO_SUBTITLES_LINES:
      const updatedMap = action.payload.reduce((map, line) => {
        map[line.num] = line;
        return map;
      }, {});

      const lines =
        state.introSubtitlesLines?.lines.map((line) => {
          return updatedMap[line.num] || line;
        }) || [];

      return {
        ...state,
        introSubtitlesLines: {
          ...(state.introSubtitlesLines || {}),
          lines,
        },
      };

    case Types.APPLY_INTRO_SUBTITLES_CHANGES:
      return (() => {
        const locale = state.introEnhancementsChanges.subtitlesEnabledState.locale;
        const enabled = state.introEnhancementsChanges.subtitlesEnabledState.enabled;

        const introSubtitles = state.introSubtitles.map((subtitles) => {
          return {
            ...subtitles,
            enabled: enabled ? subtitles.locale === locale : false,
          };
        });

        return {
          ...state,
          introSubtitles,
          introSubtitlesLines: {
            ...state.introSubtitlesLines,
            enabled,
          },
        };
      })();

    case Types.SAVE_SUBTITLES_APPROVED_STATE_SUCCESS:
      const { id, approved } = action.payload;

      const introSubtitles = state.introSubtitles.map((subtitles) => {
        return {
          ...subtitles,
          approved: subtitles.id === id ? approved : subtitles.approved,
        };
      });

      return (() => {
        return {
          ...state,
          introSubtitles,
          introSubtitlesLines: {
            ...state.introSubtitlesLines,
            approved,
          },
        };
      })();

    case Types.SET_INTRO_MEDIA_ENABLED:
      return (() => {
        const subtitleId = action.payload;
        const introEnhancements = state.introEnhancements.map((media) => {
          const enabled = media.id === subtitleId;
          const newMedia = {
            ...media,
            approved: true,
            enabled,
          };
          return newMedia;
        });
        return {
          ...state,
          introEnhancements,
        };
      })();

    case Types.SET_INTRO_ENHANCEMETS_CHANGES:
      return {
        ...state,
        introEnhancementsChanges: action.payload,
      };

    case Types.SET_SUMMARY_SUGGESTION_CARDS:
      return {
        ...state,
        summarySuggestionCards: action.payload,
      };

    case Types.RESET_SUMMARY_SUGGESTION_CARDS:
      return {
        ...state,
        summarySuggestionCards: null,
      };

    case Types.SET_QUESTION_SUGGESTION:
      return {
        ...state,
        questionSuggestion: {
          open: action.payload.open,
          multiChoice: action.payload.mcq,
          survey: action.payload.survey,
        },
      };

    case Types.RESET_QUESTION_SUGGESTION:
      return {
        ...state,
        questionSuggestion: null,
      };

    case AuthTypes.LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default createBiteReducer;
