import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { calcFontSize, calcHeight, isWeb } from '../../../utils/dimensions';
import { useIsMounted } from '../../../hooks/useIsMounted';
import SearchInput from './SearchInput';
import { ActivityIndicator, FlatList, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { MasonryFlashList } from '@shopify/flash-list';
import defaultTheme from '../../../themes/defaultTheme';
import { searchSelector } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.selectors';
import { setSearch } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.slice';

interface IProps {
  hasMore: boolean;
  withSearchInput?: boolean;
  searchInputHeight?: number;
  layout: 'list' | 'masonry';
  results: any[];
  isLoading: boolean;
  isError: boolean;
  renderFilterControls?: (props?: any) => React.ReactElement;
  setSearchInput?: (query: string) => void;
  searchText?: string;
  onSearch: (props: { query: string; withReset?: boolean; withDelayedReset?: boolean }) => Promise<void>;
  // onSearchNext?: () => Promise<void>;
  renderItem: (item: any) => React.ReactElement;
  getItemKey: (item: any) => string;
  isFlaggedToReset?: boolean;
}

const Search = ({
  hasMore,
  withSearchInput,
  searchInputHeight,
  layout,
  results,
  isLoading,
  isError,
  renderFilterControls,
  setSearchInput,
  searchText: searchTextProp,
  onSearch,
  renderItem,
  getItemKey,
  isFlaggedToReset,
}: IProps) => {
  const dispatch = useDispatch();
  const isMountedRef = useIsMounted();

  const storeSearchText = useSelector(searchSelector);
  const searchText = searchTextProp !== undefined ? searchTextProp : storeSearchText;

  const [isRefreshing, setIsRefreshing] = useState(false);

  const lastActionRef = useRef<() => void>(null);
  const requestRef = useRef(0);

  const handleSearch = useCallback(
    async ({ query = '', withDebouce = true }: { query?: string; withDebouce?: boolean } = {}) => {
      lastActionRef.current = () =>
        handleSearch({
          query,
          withDebouce: false,
        });

      setIsRefreshing(false);

      const requestId = ++requestRef.current;

      if (withDebouce) {
        await new Promise((resolve) => setTimeout(resolve, 500));
      }

      if (!isMountedRef.current || requestId !== requestRef.current) {
        return;
      }

      onSearch({ query, withReset: true });
    },
    [isMountedRef, onSearch],
  );

  const handleSearchNext = useCallback(async () => {
    lastActionRef.current = () => handleSearchNext();

    setIsRefreshing(false);

    onSearch({ query: searchText });
  }, [onSearch, searchText]);

  const handleRefresh = useCallback(async () => {
    lastActionRef.current = () => handleRefresh();

    setIsRefreshing(true);

    const requestId = ++requestRef.current;

    await onSearch({ query: searchText, withDelayedReset: true });

    if (!isMountedRef.current || requestId !== requestRef.current) {
      return;
    }

    setIsRefreshing(false);
  }, [isMountedRef, onSearch, searchText]);

  const handleTryAgain = useCallback(() => {
    lastActionRef.current?.();
  }, []);

  const handleSearchInput = useCallback(
    (query: string) => {
      if (setSearchInput) {
        setSearchInput(query);
      } else {
        dispatch(setSearch(query));
      }
      handleSearch({
        query,
        withDebouce: true,
      });
    },
    [dispatch, handleSearch, setSearchInput],
  );

  useEffect(() => {
    if (results !== null && !isFlaggedToReset) {
      return;
    }

    handleSearch({
      query: searchText,
      withDebouce: false,
    });
  }, [handleSearch, isFlaggedToReset, results, searchText]);

  const renderLoader = useCallback(() => {
    if (!isLoading && !isFlaggedToReset) {
      return null;
    }

    return (
      <S.Loading>
        <ActivityIndicator color='#000' />
      </S.Loading>
    );
  }, [isLoading, isFlaggedToReset]);

  const data = useMemo(() => (isFlaggedToReset ? [] : results || []), [isFlaggedToReset, results]);

  return (
    <>
      {withSearchInput && (
        <SearchInput
          value={searchText}
          placeholder='Search'
          inputHeight={searchInputHeight}
          // isLoading={isSearchWithQueryInProgress}
          onInput={handleSearchInput}
        />
      )}

      {renderFilterControls?.({ query: searchText })}

      {/* {!results.length && isSearching && !isRefreshing && (
        <S.Loading>
          <ActivityIndicator color='#000' />
        </S.Loading>
      )} */}

      {!isLoading && !isRefreshing && !isError && results && !results.length && (
        <S.NoResultsContainer>No results</S.NoResultsContainer>
      )}

      {layout === 'list' && (
        <FlatList
          data={data}
          numColumns={1}
          renderItem={renderItem}
          keyExtractor={getItemKey}
          refreshing={isRefreshing}
          // refreshControl={
          //   !isLoading && !isFlaggedToReset ? (
          //     <RefreshControl refreshing={false} onRefresh={handleRefresh} />
          //   ) : undefined
          // }
          ListFooterComponent={renderLoader}
          contentContainerStyle={S.listStyles.list}
          ItemSeparatorComponent={ListItemSeparator}
          onEndReached={hasMore && !isLoading && !isRefreshing && !isError ? handleSearchNext : undefined}
          onEndReachedThreshold={isWeb ? 0.001 : 1}
        />
      )}
      {layout === 'masonry' && (
        <MasonryFlashList
          data={data}
          numColumns={2}
          renderItem={renderItem}
          estimatedItemSize={200}
          keyExtractor={getItemKey}
          refreshing={isRefreshing}
          // refreshControl={
          //   !isLoading && !isFlaggedToReset ? (
          //     <RefreshControl refreshing={false} onRefresh={handleRefresh} />
          //   ) : undefined
          // }
          ListFooterComponent={renderLoader}
          contentContainerStyle={S.listStyles.list}
          onEndReached={hasMore && !isLoading && !isRefreshing && !isError ? handleSearchNext : undefined}
          onEndReachedThreshold={isWeb ? 0.001 : 1}
        />
      )}

      {isError && (
        <S.ErrorContainer>
          <S.ErrorText>Something went wrong</S.ErrorText>
          <S.TryAgain onClick={handleTryAgain}>
            <S.TryAgainText>Try again</S.TryAgainText>
          </S.TryAgain>
        </S.ErrorContainer>
      )}
    </>
  );
};
export default Search;

const ListItemSeparator = () => {
  return <S.ListItemSeparator />;
};

const S = {
  Container: styled.div`
    flex: 1;
    padding: 0 12px;
  `,
  listStyles: StyleSheet.create({
    list: {
      maxHeight: '100%',
      paddingLeft: calcHeight(4),
      paddingRight: calcHeight(4),
      paddingBottom: calcHeight(4),
    },
  }),
  Loading: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${calcHeight(40)}px;
  `,
  ErrorContainer: styled.div`
    margin-top: ${calcHeight(16)}px;
    align-items: center;
    justify-content: center;
  `,
  ErrorText: styled.div`
    color: ${defaultTheme.colors.failRed};
    font-size: ${calcFontSize(20)}px;
    font-family: ${defaultTheme.fontFamilies.Arimo};
  `,
  TryAgain: styled.div`
    ${isWeb ? 'cursor: pointer;' : ''}
  `,
  TryAgainText: styled.div`
    color: ${defaultTheme.colors.failRed};
    font-size: ${calcFontSize(20)}px;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    text-decoration: underline;
  `,
  NoResultsContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${calcHeight(100)}px;
    color: ${defaultTheme.colors.gray19};
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: ${calcFontSize(14)}px;
  `,
  ListItemSeparator: styled.div`
    margin: 0 12px;
    height: ${calcHeight(1)}px;
    background-color: ${defaultTheme.colors.gray30};
  `,
};
