import React, { useCallback, useRef } from 'react';

import withRetry from '../../../utils/withRetry';
import Search from './Search.web';
import { useDispatch, useSelector } from 'react-redux';
import { createCloudAsset } from '../../../store/cloudAssets/cloudAssets.slice';
import { v4 as uuid } from 'uuid';
import { searchGiphyGifs, searchGiphyTrendingGifs } from '../../../services/giphy';
import ImageSearchItem from './ImageSearchItem.web';
import { getImageMetadata } from '../../../utils/getImageMetadata';
import {
  addToTimeline,
  addUndo,
  deleteProcessingTimelineItemState,
  removeEmptyTimelineLayers,
  removeUndoRedoById,
  saveVideo,
  setProcessingTimelineItemState,
  updateCanvas,
  updateTimelineItem,
  updateTimelineItemLayer,
} from '../../../store/videoEditor/videoEditor.slice';
import { videoResolutionSeletor } from '../../../store/videoEditor/videoEditor.selectors';
import { getItemCanvasSize } from '../utils/getItemCanvasSize';
import { retryFunctions } from '../../../store/videoEditor/videoEditor.data';
import { getIsItemOnTimeline } from '../utils/getIsItemOnTimeline';
import {
  stockFileTypeSelector,
  stockGifResultsSelector,
} from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.selectors';
import { setStockGifResults } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.slice';
import { logError } from '../../../store/appActivity/appActivity.slice';
import { FileTypeSelector } from './FileTypeSelector';
import { STOCK_FILE_TYPES } from '../videoEditor.constants';
import { useIsMounted } from '../../../hooks/useIsMounted';

const StockGifs = () => {
  const dispatch = useDispatch();
  const isMountedRef = useIsMounted();

  const resolution = useSelector(videoResolutionSeletor);
  const stockGifResults = useSelector(stockGifResultsSelector);
  const fileType = useSelector(stockFileTypeSelector);

  const requestIdRef = useRef(0);

  const handleSearch = useCallback(
    async ({
      query,
      withReset,
      withDelayedReset,
    }: {
      query: string;
      withReset?: boolean;
      withDelayedReset?: boolean;
    }) => {
      const requestId = ++requestIdRef.current;

      try {
        dispatch(
          setStockGifResults({
            data: withReset ? null : stockGifResults.data,
            totalResults: null,
            isLoading: true,
            error: null,
          }),
        );

        const offset = withReset || withDelayedReset ? 0 : stockGifResults.data?.length || 0;
        const service = query ? () => searchGiphyGifs({ query, offset }) : () => searchGiphyTrendingGifs({ offset });

        const result = await withRetry(service, {
          errorContext: {
            data: {
              action: 'StockGifs.handleSearch',
            },
          },
        });

        if (requestId !== requestIdRef.current || !isMountedRef.current) {
          return;
        }

        dispatch(
          setStockGifResults({
            data: withReset || withDelayedReset ? result.data : [...(stockGifResults.data || []), ...result.data],
            totalResults: result.pagination.total_count,
            isLoading: false,
          }),
        );
      } catch (error) {
        if (requestId !== requestIdRef.current || !isMountedRef.current) {
          return;
        }

        dispatch(
          logError({
            event: 'StockGifs.handleSearch: error',
            data: {
              error,
            },
          }),
        );

        dispatch(
          setStockGifResults({
            data: withDelayedReset ? [] : stockGifResults.data,
            totalResults: withDelayedReset ? null : stockGifResults.totalResults,
            isLoading: false,
            error,
          }),
        );
      }
    },
    [dispatch, isMountedRef, stockGifResults.data, stockGifResults.totalResults],
  );

  const getItemKey = useCallback((item) => `${item.id}`, []);

  const handleUseItem = useCallback(
    async (item) => {
      const processId = uuid();

      const timelineItemId = uuid();
      dispatch(
        addToTimeline({
          timelineItem: {
            id: timelineItemId,
            type: 'gif',
          },
        }),
      );
      dispatch(
        updateTimelineItemLayer({
          timelineItemId,
        }),
      );
      dispatch(removeEmptyTimelineLayers());

      const undoId = uuid();
      dispatch(
        addUndo({
          id: undoId,
        }),
      );

      // setIsUploadingMap((prev) => {
      //   return { ...prev, [item.id]: true };
      // });

      const processingLabel = 'Stock gif';

      const handleFail = (error: any) => {
        dispatch(
          setProcessingTimelineItemState({
            timelineItemId,
            label: processingLabel,
            isProcessing: false,
            isDone: false,
            retryFunctionId,
            error: {
              details: error,
            },
          }),
        );
      };

      const retryFunctionId = uuid();
      const applyItemFunction = async () => {
        try {
          dispatch(
            setProcessingTimelineItemState({
              timelineItemId,
              label: processingLabel,
              isProcessing: true,
              isDone: false,
              retryFunctionId,
            }),
          );

          const itemUrl = item.images.original.url;

          const response = await fetch(itemUrl);
          const blob = await response.blob();

          const fileMeta = await getImageMetadata({ blob });
          const fileName = itemUrl.split('/').pop().split('?')[0].split('#')[0];
          fileMeta.name = fileName;

          const file = new File([blob], fileName, {
            type: blob.type, // Preserves the MIME type
            lastModified: new Date().getTime(), // Converts the date to a timestamp
          });

          dispatch(
            createCloudAsset({
              processId,
              fileType: 'gif',
              fileMeta,
              file,
              originalSrc: 'giphy',
              originalData: item,
              onCacheReady: ({ cloudAsset }) => {
                dispatch(
                  removeUndoRedoById({
                    id: undoId,
                  }),
                );

                dispatch(
                  setProcessingTimelineItemState({
                    timelineItemId,
                    label: processingLabel,
                    isProcessing: false,
                    isDone: true,
                  }),
                );
                setTimeout(() => {
                  dispatch(
                    deleteProcessingTimelineItemState({
                      timelineItemId,
                    }),
                  );
                }, 5000);

                const isOnTimeline = getIsItemOnTimeline(timelineItemId);

                if (!isOnTimeline) {
                  return;
                }
                // setIsUploadingMap((prev) => {
                //   const newMap = { ...prev };
                //   delete newMap[item.id];
                //   return newMap;
                // });

                const canvasSize = getItemCanvasSize({
                  width: fileMeta.width,
                  height: fileMeta.height,
                  resolution,
                  scaleUp: true,
                });

                // dispatch(
                //   addToTimeline({
                //     timelineItem: {
                //       type: 'gif',
                //       cloudAssetId: cloudAsset.id,
                //       x: 0,
                //       y: 0,
                //       width: canvasSize.width,
                //       height: canvasSize.height,
                //     },
                //   }),
                // );

                dispatch(
                  updateTimelineItem({
                    id: timelineItemId,
                    cloudAssetId: cloudAsset.id,
                    x: 0,
                    y: 0,
                    width: canvasSize.width,
                    height: canvasSize.height,
                  }),
                );

                dispatch(updateCanvas({}));
                dispatch(
                  addUndo({
                    id: undoId,
                  }),
                );
                dispatch(saveVideo({}));
              },
              onFail: handleFail,
            }),
          );
        } catch (error) {
          handleFail(error);
        }
      };
      retryFunctions[retryFunctionId] = applyItemFunction;
      applyItemFunction();
    },
    [dispatch, resolution],
  );

  const renderItem = useCallback(
    ({ item }) => {
      return <ImageSearchItem url={item.images.original.url || null} item={item} onSelect={handleUseItem} />;
    },
    [handleUseItem],
  );

  const renderFilterControls = useCallback(() => {
    return (
      <>
        <FileTypeSelector fileType={fileType} fileTypes={STOCK_FILE_TYPES} />
      </>
    );
  }, [fileType]);

  return (
    <>
      <Search
        results={stockGifResults.data}
        isLoading={stockGifResults.isLoading}
        isError={stockGifResults.error}
        hasMore={stockGifResults.data?.length < stockGifResults.totalResults}
        withSearchInput
        layout='masonry'
        renderFilterControls={renderFilterControls}
        onSearch={handleSearch}
        renderItem={renderItem}
        getItemKey={getItemKey}
      />
    </>
  );
};
export default StockGifs;
