import { ILine, ISubtitlesLine } from '../types/subtitles';

const formatSubtitles = (data?: ISubtitlesLine[]) => {
  if (!data) {
    return undefined;
  }

  return data.map((line): ILine => {
    const startTimeText = line.time_line.match(/:(\d\d:\d\d)\./)[1];
    const endTimeText = line.time_line.match(/>.*:(\d\d:\d\d)\./)[1];
    const startTimeTextWithMs = line.time_line.match(/:(\d\d:\d\d\.\d\d\d) -/)[1];
    const endTimeTextWithMs = line.time_line.match(/>.*:(\d\d:\d\d\.\d\d\d)/)[1];

    let time = startTimeTextWithMs.split(':');
    const startTime = parseInt(time[0], 10) * 60 + parseFloat(time[1]);

    time = endTimeTextWithMs.split(':');
    const endTime = parseInt(time[0], 10) * 60 + parseFloat(time[1]);

    return {
      ...line,
      startTime,
      endTime,
      startTimeText,
      endTimeText,
    };
  });
};

export default formatSubtitles;
