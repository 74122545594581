import React, { useEffect, useRef, useState } from 'react';
import LogoImage from '../../assets/images/bite-logo.png';
import Button from '../../components/shared/buttons/Button/Button';
import styled from 'styled-components';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import defaultTheme from '../../themes/defaultTheme';
import { saveVideo, setIsPlaying, setIsDraft } from '../../store/videoEditor/videoEditor.slice';
import {
  isPlayingSeletor,
  isVideoAutoSavingSeletor,
  videoDurationSeletor,
  videoSelector,
} from '../../store/videoEditor/videoEditor.selectors';
import { EToastTypes } from '../../utils/constants/toastConfig';
import Toast from 'react-native-toast-message';
import SavedIcon from '../../assets/icons/videoEditor/saved.svg';
import { useNavigation } from '@react-navigation/native';
import { ActivityIndicator } from 'react-native';

const continueButtonStyle = {
  paddingLeft: 16,
  paddingRight: 16,
  height: 45,
};

const TopBar = ({ route }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const video = useSelector(videoSelector);
  const isVideoAutoSaving = useSelector(isVideoAutoSavingSeletor);
  const isPlaying = useSelector(isPlayingSeletor);
  const duration = useSelector(videoDurationSeletor);

  const isInitialRender = useRef(true);

  const [isSaved, setIsSaved] = useState(false);
  const isSavedTimeout = useRef(null);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    clearTimeout(isSavedTimeout.current);

    if (isVideoAutoSaving) {
      setIsSaved(false);
      return;
    }

    setIsSaved(true);

    isSavedTimeout.current = setTimeout(() => {
      setIsSaved(false);
    }, 3000);

    return () => {
      clearTimeout(isSavedTimeout.current);
    };
  }, [isVideoAutoSaving]);

  const handleContinueLater = useCallback(async () => {
    if (isPlaying) {
      dispatch(setIsPlaying(false));
    }

    dispatch(
      saveVideo({
        delay: 0,
        isManual: true,
        onDone: () => {
          navigation.goBack();
        },
        onError: () => {
          Toast.show({
            type: EToastTypes.networkError,
            topOffset: 0,
          });
        },
      }),
    );
  }, [dispatch, isPlaying, navigation]);

  const handleSave = useCallback(async () => {
    if (isPlaying) {
      dispatch(setIsPlaying(false));
    }

    dispatch(setIsDraft(false));

    dispatch(
      saveVideo({
        delay: 0,
        isManual: true,
        onDone: route.params.onContinue,
        onError: () => {
          Toast.show({
            type: EToastTypes.networkError,
            topOffset: 0,
          });
        },
      }),
    );
  }, [dispatch, isPlaying, route.params]);

  return (
    <>
      <S.Logo src={LogoImage} />
      <S.Beta>Beta</S.Beta>
      <S.Grow />
      <S.RightControls>
        {isVideoAutoSaving && (
          <S.Saved>
            <ActivityIndicator />
            <S.SavedText>Saving...</S.SavedText>
          </S.Saved>
        )}
        {isSaved && (
          <S.Saved>
            <SavedIcon />
            <S.SavedText>Saved{video.isDraft ? ' as draft' : ''}</S.SavedText>
          </S.Saved>
        )}
        <Button
          text='Continue later'
          textColor={defaultTheme.colors.primaryBlue}
          border={defaultTheme.colors.primaryBlue}
          fill={defaultTheme.colors.white}
          style={continueButtonStyle}
          onPress={handleContinueLater}
        />
        <Button
          text='Continue Bite'
          textColor={defaultTheme.colors.white}
          fill={defaultTheme.colors.primaryBlue}
          style={continueButtonStyle}
          disabled={duration === 0}
          onPress={handleSave}
        />
      </S.RightControls>
    </>
  );
};
export default TopBar;

const S = {
  Logo: styled.img`
    width: 25px;
    height: 32px;
  `,
  Beta: styled.div`
    margin-left: 23px;
    padding: 0px 6px;
    height: 17px;
    color: ${defaultTheme.colors.lightGray40};
    font-size: 12px;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-weight: 700;
    line-height: 15px;
    border-radius: 10px;
    border: 1px solid ${defaultTheme.colors.backgroundPurple};
  `,
  Grow: styled.div`
    flex-grow: 1;
  `,
  RightControls: styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
  `,
  Saved: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-right: 8px;
  `,
  SavedText: styled.div`
    color: ${defaultTheme.colors.text};
    font-size: 14px;
    font-family: ${defaultTheme.fontFamilies.Arimo};
  `,
};
