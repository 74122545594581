import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInitialState } from './videoEditorLeftSidebar.types';
import { cleanVideoEditor } from '../videoEditor/videoEditor.slice';

const sliceName = 'VIDEO_EDITOR_LEFT_SIDEBAR';

const getDefaultCloud = () => ({
  fileType: null,
  data: null,
  page: 0,
  totalPages: null,
  isLoading: true,
  error: null,
  lastDataUpdateTs: null,
  lastSearchTs: null,
});

const getDefaultStockVideos = () => ({
  fileType: null,
  data: null,
  next: null,
  isLoading: true,
  error: null,
});

const getDefaultStockStickers = () => ({
  fileType: null,
  data: null,
  totalResults: null,
  isLoading: true,
  error: null,
});

const CLOUD_FILE_TYPES: IInitialState['cloudFileType'][] = [null, 'video', 'image', 'gif', 'audio'];
const STOCK_FILE_TYPES: IInitialState['stockFileType'][] = ['video', 'image', 'gif', 'sticker'];

const setCloudFileType = (state: IInitialState, fileType) => {
  fileType = fileType === 'sticker' ? 'gif' : fileType;
  fileType = !CLOUD_FILE_TYPES.includes(fileType) ? CLOUD_FILE_TYPES[0] : fileType;

  if (state.cloudFileType === fileType) {
    return;
  }

  state.cloudFileType = fileType;

  resetCloudResults(state);
};
const setStockFileType = (state: IInitialState, fileType) => {
  if (state.stockFileType === fileType) {
    return;
  }

  state.stockFileType = !STOCK_FILE_TYPES.includes(fileType) ? STOCK_FILE_TYPES[0] : fileType;

  resetStockResults(state);
};

const resetCloudResults = (state: IInitialState) => {
  state.cloudResults = getDefaultCloud();
  state.scopedResults = getDefaultCloud();
  state.favoriteResults = getDefaultCloud();
  state.brandResults = getDefaultCloud();
};
const resetStockResults = (state: IInitialState) => {
  state.stockVideoResults = getDefaultStockVideos();
  state.stockImageResults = getDefaultStockVideos();
  state.stockGifResults = getDefaultStockStickers();
  state.stockStickerResults = getDefaultStockStickers();
};
const resetResults = (state: IInitialState) => {
  resetCloudResults(state);
  resetStockResults(state);
};

export const initialState: IInitialState = {
  primaryTab: 'CLOUD',
  search: '',
  cloudFileType: null,
  stockFileType: 'video',
  bite: null,
  generation: null,
  orientation: null,
  cloudResults: getDefaultCloud(),
  scopedResults: getDefaultCloud(),
  favoriteResults: getDefaultCloud(),
  brandResults: getDefaultCloud(),
  stockVideoResults: getDefaultStockVideos(),
  stockImageResults: getDefaultStockVideos(),
  stockGifResults: getDefaultStockStickers(),
  stockStickerResults: getDefaultStockStickers(),
  flaggedToReset: {},
  overlay: null,
};

const analyticsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setPrimaryTab: (state, { payload }: PayloadAction<IInitialState['primaryTab']>) => {
      state.primaryTab = payload;
    },
    setSearch: (state, { payload }: PayloadAction<string>) => {
      if (state.search === payload) {
        // to avoid resetting results
        return;
      }

      state.search = payload;

      resetResults(state);
    },
    setFileType: (
      state,
      { payload }: PayloadAction<IInitialState['cloudFileType'] | IInitialState['stockFileType']>,
    ) => {
      setCloudFileType(state, payload);
      setStockFileType(state, payload);
    },
    setBite: (state, { payload }: PayloadAction<IInitialState['bite']>) => {
      if (state.bite === payload) {
        // to avoid resetting results
        return;
      }

      state.bite = payload;
      state.scopedResults = getDefaultCloud();
    },
    setGeneration: (state, { payload }: PayloadAction<IInitialState['generation']>) => {
      if (state.generation === payload) {
        // to avoid resetting results
        return;
      }

      state.generation = payload;
      state.scopedResults = getDefaultCloud();
    },
    setOrientation: (state, { payload }: PayloadAction<IInitialState['orientation']>) => {
      if (state.orientation === payload) {
        // to avoid resetting results
        return;
      }

      state.orientation = payload;

      // right now orientation is only used in stock images and videos search
      state.stockImageResults = getDefaultStockVideos();
      state.stockVideoResults = getDefaultStockVideos();
    },
    setCloudResults: (state, { payload }: PayloadAction<Partial<IInitialState['cloudResults']>>) => {
      state.cloudResults = {
        ...state.cloudResults,
        ...payload,
      };
    },
    setScopedResults: (state, { payload }: PayloadAction<Partial<IInitialState['scopedResults']>>) => {
      state.scopedResults = {
        ...state.scopedResults,
        ...payload,
      };
    },
    setFavoriteResults: (state, { payload }: PayloadAction<Partial<IInitialState['favoriteResults']>>) => {
      state.favoriteResults = {
        ...state.favoriteResults,
        ...payload,
      };
    },
    setBrandResults: (state, { payload }: PayloadAction<Partial<IInitialState['brandResults']>>) => {
      state.brandResults = {
        ...state.brandResults,
        ...payload,
      };
    },
    setStockVideoResults: (state, { payload }: PayloadAction<Partial<IInitialState['stockVideoResults']>>) => {
      state.stockVideoResults = {
        ...state.stockVideoResults,
        ...payload,
      };
    },
    setStockImageResults: (state, { payload }: PayloadAction<Partial<IInitialState['stockImageResults']>>) => {
      state.stockImageResults = {
        ...state.stockImageResults,
        ...payload,
      };
    },
    setStockGifResults: (state, { payload }: PayloadAction<Partial<IInitialState['stockGifResults']>>) => {
      state.stockGifResults = {
        ...state.stockGifResults,
        ...payload,
      };
    },
    setStockStickerResults: (state, { payload }: PayloadAction<Partial<IInitialState['stockStickerResults']>>) => {
      state.stockStickerResults = {
        ...state.stockStickerResults,
        ...payload,
      };
    },
    // overlay
    setOverlay: (state, { payload }: PayloadAction<IInitialState['overlay']>) => {
      state.overlay = payload;
    },
    resetFavoriteResults: (state) => {
      state.favoriteResults = getDefaultCloud();
    },
    resetBrandResults: (state) => {
      state.brandResults = getDefaultCloud();
    },
    setFlaggedToReset: (state, { payload }: PayloadAction<IInitialState['flaggedToReset']>) => {
      Object.entries(payload).forEach(([key, value]) => {
        if (!value) {
          delete state.flaggedToReset[key];
        }

        state.flaggedToReset[key] = value;
      });
    },
  },
  extraReducers: {
    [cleanVideoEditor.type]: () => initialState,
  },
});
export default analyticsSlice.reducer;

export const {
  setPrimaryTab,
  setSearch,
  setFileType,
  setBite,
  setGeneration,
  setOrientation,
  // setVideosUsedInBitesResults,
  setCloudResults,
  setScopedResults,
  setFavoriteResults,
  setBrandResults,
  setStockVideoResults,
  setStockImageResults,
  setStockGifResults,
  setStockStickerResults,
  setOverlay,
  resetFavoriteResults,
  resetBrandResults,
  setFlaggedToReset,
} = analyticsSlice.actions;
