import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import Types, {
  EIntroMediaProcessingStatus,
  IBitePostBody,
  IIntroMediaProcessingStatus,
  ITrackingMeta,
  ITrackingOptions,
} from './createBite.types';
import {
  activeSubtitlesSelector,
  createBiteSelector,
  defaultSubtitlesSelector,
  fetchingIntroSubtitlesLinesSelector,
  introEnhancementsSelector,
  introMediaProcessingStatusSelector,
  introSubtitlesLinesSelector,
  introSubtitlesSelector,
} from './createBite.selectors';
import { ICreateBiteState } from './createBite.reducer';
import { biteQuestionSelector } from '../biteQuestion/biteQuestion.selectors';
import { BiteQuestionState } from '../biteQuestion/biteQuestion.reducer';
import { summaryInitState } from '../biteSummary/biteSummary.reducer';
import { biteSummaryCardsSelector } from '../biteSummary/biteSummary.selectors';
import { ISummaryCard } from '../../types/biteSummary';
import * as calls from '../api/bites-api/calls/bite.calls';
import { editBiteIntro } from '../api/bites-api/calls/bite.calls';
import { formatQuestionToServer, formatSummaryCardsToServer } from '../../utils/formatDataToServer';
import { activeOrganizationSelector } from '../auth/auth.selectors';
import { IUserOrganizations } from '../auth/auth.types';
import { biteSelector, selectedBiteSelector } from '../bite/bite.selectors';
import {
  cleanEditAndCreateBiteState,
  indexBites,
  setBites,
  setBiteToEdit,
  setCreatedBiteIntroSection,
  setHasBites,
  updateBiteSection,
  updateSelectedBite,
} from '../bite/bite.actions';
import {
  clearCreateBiteState,
  IEditBiteAction,
  IEditBiteIntroMediaAction,
  IUpdateBiteAction,
  IUploadQuiz,
  IUploadVideoAction,
  postOrUpdateBiteError,
  postOrUpdateBiteSuccess,
  setBiteIntro,
  setBiteIntroEdited,
  setBiteIntroEnhancementsEdited,
  setBiteQuestionEdited,
  setBiteSummaryEdited,
  setIntroEnhancementsChanges,
  setIntroMediaEnabled,
  setIntroMediaProcessingStatus,
  applyIntroSubtitlesChanges,
  setIntroSubtitlesLines,
  setIntroTask,
  setIntroVideoMediaUri,
  setQuestionTask,
  ISelectContentLocale,
  fetchIntroSubtitlesLines as fetchIntroSubtitlesLinesAction,
  setFetchingIntroSubtitlesLines,
  saveSubtitlesApprovedStateSuccess,
  defineIntroSubtitlesNullLocale,
} from './createBites.actions';
import { EEnhanceType, IBiteItem, QuestionSection, TBiteSection } from '../../types/bite';
import { log, logError, setCurrentFlow, trackEvent } from '../appActivity/appActivity.slice';
import { userSelector } from '../../store/auth/auth.selectors';
import { IAction } from '../common/types';
import { formatBiteFromServer } from '../../utils/formatDataFromServer';
import { setCreatedSummary } from '../biteSummary/biteSummary.actions';
import { cloneDeep, isEqual } from 'lodash';
import { addToDrafts } from '../drafts/drafts.slice';
import { loadNextPage } from '../feed/feed.slice';
import { ISubtitles, ISubtitlesLines } from '../../types/subtitles';
import { createPlaylistSelector } from '../createPlaylist/createPlaylist.selectors';
import { postPlaylist, updatePlaylist, updateSelectedBites } from '../createPlaylist/createPlaylist.actions';
import Toast from 'react-native-toast-message';
import { EToastTypes } from '../../utils/constants/toastConfig';
import store from '../index';
import { PayloadAction } from '@reduxjs/toolkit';
import { trackIntroMediaProcessingSubtitles } from '../introVideoPolling.saga';
import withRetry from '../../utils/withRetry';
import shortid from 'shortid';

function* postAndUpdateQuizBite({ payload: { playlistSection, callback, onError } }: IAction<IUploadQuiz>) {
  const { selectedBite } = yield select(biteSelector);
  if (selectedBite) {
    yield updateBiteSaga(callback, onError);
    return;
  }
  yield postBiteSaga(playlistSection, callback, onError);
}

function* uploadVideoSaga({
  payload: { biteId, uploadVideo, isEditMode, onSuccess, onError },
}: IAction<IUploadVideoAction>) {
  try {
    yield put(
      log({
        event: 'upload_video_start',
        bite_id: `${biteId}`,
        is_edit_mode: isEditMode,
      }),
    );

    yield put(
      setIntroMediaProcessingStatus({
        biteId,
        all: EIntroMediaProcessingStatus.PROCESSING,
        audioExport: EIntroMediaProcessingStatus.INACTIVE,
        videoExport: EIntroMediaProcessingStatus.INACTIVE,
        s3: EIntroMediaProcessingStatus.PROCESSING,
        original: EIntroMediaProcessingStatus.INACTIVE,
        enhancements: EIntroMediaProcessingStatus.INACTIVE,
        subtitles: EIntroMediaProcessingStatus.INACTIVE,
        summarySuggestion: EIntroMediaProcessingStatus.INACTIVE,
        questionSuggestion: EIntroMediaProcessingStatus.INACTIVE,
        biteNameSuggestion: EIntroMediaProcessingStatus.INACTIVE,
        coverSuggestion: EIntroMediaProcessingStatus.INACTIVE,
      }),
    );
    const { taskId, mediaUri } = yield uploadVideo();

    if (biteId) {
      yield editBiteIntro(biteId, { task_id: taskId });
    }

    if (isEditMode) {
      yield put(setIntroVideoMediaUri(mediaUri));
    }

    yield put(updateSelectedBite({ no_sections: false }));
    yield put(setIntroTask(taskId));
    yield put(
      setIntroMediaProcessingStatus({
        biteId,
        all: EIntroMediaProcessingStatus.SUCCESS,
        s3: EIntroMediaProcessingStatus.SUCCESS,
        original: EIntroMediaProcessingStatus.SUCCESS,
        enhancements: EIntroMediaProcessingStatus.INACTIVE,
        subtitles: EIntroMediaProcessingStatus.INACTIVE,
      }),
    );
    if (typeof onSuccess === 'function') {
      onSuccess();
    }
    yield put(
      log({
        event: 'upload_video_success',
        bite_id: `${biteId}`,
        is_edit_mode: isEditMode,
      }),
    );
  } catch (error) {
    yield put(
      setIntroMediaProcessingStatus({
        biteId,
        all: EIntroMediaProcessingStatus.ERROR,
        s3: EIntroMediaProcessingStatus.ERROR,
      }),
    );
    yield put(
      logError({
        event: 'upload_video_failed',
        bite_id: biteId,
        is_edit_mode: isEditMode,
        error,
      }),
    );
    if (typeof onError === 'function') {
      onError();
    }
  }
}

function* waitForTaskId() {
  const { taskId }: ICreateBiteState = yield select(createBiteSelector);
  if (!taskId) {
    yield delay(1000);
    return yield waitForTaskId();
  }
  return taskId;
}

function* fetchIntroSubtitlesLines({ payload }: PayloadAction<ISubtitles['id']>) {
  try {
    const fetchingIntroSubtitlesLines = yield select(fetchingIntroSubtitlesLinesSelector);
    if (fetchingIntroSubtitlesLines[payload]) {
      return;
    }
    yield put(setFetchingIntroSubtitlesLines({ id: payload, isFetching: true }));

    yield put(
      log({
        event: 'fetchIntroSubtitlesLines',
        data: { id: payload },
      }),
    );

    const { data: subtitlesLines }: { data: ISubtitlesLines } = yield calls.getSubtitlesLines(payload);

    yield put(setIntroSubtitlesLines(subtitlesLines));
  } catch (error) {
    yield put(setFetchingIntroSubtitlesLines({ id: payload, isFetching: false }));

    yield put(logError({ error }));

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

function* saveMediaChanges(changes: ICreateBiteState['introEnhancementsChanges']) {
  if (changes?.mediaId) {
    const biteInfo = yield select(introEnhancementsSelector);

    let obj = biteInfo.find((o) => o.id === changes?.mediaId);
    yield put(
      trackEvent({
        event: 'save_enhancements',
        props: { enabled: obj.enhance_type !== EEnhanceType.original },
      }),
    );

    yield calls.saveMediaChanges(changes.mediaId, {
      approved: true,
      enabled: true,
    });

    store.dispatch(setIntroMediaEnabled(changes.mediaId));
  }
}

function* saveSubtitlesChanges(changes: ICreateBiteState['introEnhancementsChanges']) {
  const change = changes?.subtitlesEnabledState;
  const selectedBite = yield select(selectedBiteSelector);

  if (change) {
    const locale = change.locale;
    const enabled = change.enabled;

    const activeSubtitles = yield select(activeSubtitlesSelector);

    if (!enabled && !activeSubtitles) {
      return;
    }

    yield put(
      trackEvent({
        event: 'save_subtitles',
        props: { enabled },
      }),
    );

    const defaultSubtitles = yield select(defaultSubtitlesSelector);
    const introSubtitles = yield select(introSubtitlesSelector);

    const subtitlesId = enabled
      ? introSubtitles.find((subtitles) => subtitles.locale === locale).id
      : activeSubtitles?.id || defaultSubtitles.id;

    yield calls.updateSubtitlesState({ subtitlesId, enabled });

    yield put(applyIntroSubtitlesChanges());
  }

  // reindex in any case because there might have been changes in the subtitles lines
  // but after the changes above are saved
  yield put(
    indexBites({
      biteIds: [selectedBite.id],
    }),
  );
}

function* saveIntroEnhancementsChanges() {
  try {
    const { introEnhancementsChanges: changes }: ICreateBiteState = yield select(createBiteSelector);

    yield all([saveMediaChanges(changes), saveSubtitlesChanges(changes)]);

    yield put(setIntroEnhancementsChanges({}));
    yield put(setBiteIntroEnhancementsEdited(true));
  } catch (error) {
    yield put(logError({ error }));

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

function* saveIntroSubtitlesLinesSaga() {
  try {
    yield put(
      trackEvent({
        event: 'update_subtitles_lines',
      }),
    );

    const { id, task_id, locale: subtitlesLocale, lines } = yield select(introSubtitlesLinesSelector);
    let locale = subtitlesLocale;

    let vtt = 'WEBVTT\n\n';
    lines.forEach((subtitle) => {
      vtt += `${subtitle.time_line}\n${subtitle.line}\n\n`;
    });

    if (!locale) {
      const { data } = yield calls.defineSubtitlesLocale(task_id);
      yield put(defineIntroSubtitlesNullLocale({ locale: data.locale, taskId: task_id }));
      locale = data.locale;
    }

    yield calls.updateSubtitlesLines({
      taskId: task_id,
      locale,
      vtt,
    });
    yield put(fetchIntroSubtitlesLinesAction(id));
  } catch (error) {
    yield put(logError({ error }));

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

function* postBiteSaga(playlistSection: 'intro' | 'body', callback?: () => void, onError?: () => void) {
  let {
    biteName,
    biteIcon,
    cover,
    biteSettings,
    introMedia,
    description,
    questionTaskId,
    linked_cover_url,
    introMediaProcessingStatus,
  }: ICreateBiteState = yield select(createBiteSelector);
  const activeOrganization: IUserOrganizations = yield select(activeOrganizationSelector);

  const biteQuestion: BiteQuestionState = yield select(biteQuestionSelector);

  const summaryCards: ISummaryCard[] = yield select(biteSummaryCardsSelector);

  const biteSections: TBiteSection[] = [
    {
      type: 'content',
      content: {
        content: 'somestring',
      },
    },
  ];
  const taskId = introMediaProcessingStatus.all !== EIntroMediaProcessingStatus.INACTIVE && (yield waitForTaskId());
  if (taskId && !introMedia) {
    try {
      biteSections.push({
        type: 'intro' as 'intro',
        media: {},
        task_id: taskId,
      });
      yield put(setIntroTask(null));
    } catch (error) {
      yield put(postOrUpdateBiteError());
      yield put(
        logError({
          event: 'postBiteSaga (1): error',
          error,
        }),
      );
    }
  }

  if (introMedia) {
    const introSection = {
      type: 'intro' as 'intro',
      media: { id: introMedia?.id },
    };
    biteSections.push(introSection);
  }

  if (summaryCards.length) {
    const summarySection = {
      type: 'summary' as 'summary',
      summary_cards: formatSummaryCardsToServer(summaryCards),
      media: null,
    };
    biteSections.push(summarySection);
  }

  if (!biteQuestion.skipped) {
    const questionSection: QuestionSection = {
      type: 'question',
      media: biteQuestion.media ? { id: biteQuestion.media.id } : null,
      questions: [formatQuestionToServer(biteQuestion)],
    };
    if (questionTaskId) {
      try {
        const taskResponse = yield calls.getUploadedVideoByTaskId(questionTaskId);
        const resMedia = taskResponse?.data?.media;
        questionSection.media = { id: resMedia?.id! };
        questionSection.task_id = questionTaskId;
      } catch (error) {
        yield put(
          logError({
            event: 'postBiteSaga (questionTaskId): error',
            error,
          }),
        );
      }
      yield put(setQuestionTask(null));
    }
    biteSections.push(questionSection);
  }

  const bite: IBitePostBody = {
    organization: activeOrganization.id,
    subject: biteName,
    description,
    icon: biteIcon?.id,
    background: 1,
    cover,
    linked_cover_url,
    background_url: null,
    language: 1, // TODO -
    ...biteSettings,
    bite_sections: biteSections,
  };

  if (activeOrganization.default_sharing_mode) {
    bite.sharing_mode = activeOrganization.default_sharing_mode;
  }

  try {
    const { data } = yield call(calls.postAndUpdateBite, bite);

    if (playlistSection === 'body') {
      yield put(
        trackEvent({
          event: 'save_question',
          props: { quiz_id: data.id },
        }),
      );
    }

    yield put(postOrUpdateBiteSuccess(data));

    const { selectedBites, editedPlaylist } = yield select(createPlaylistSelector);

    yield put(
      updateSelectedBites([
        ...selectedBites,
        {
          id: data.id,
          bite_preview: data.bite,
          cover_url: data.cover_url,
          subject: data.subject,
          playlist_section: playlistSection,
        },
      ]),
    );

    if (!editedPlaylist?.id) {
      yield put(postPlaylist());
    } else {
      yield put(updatePlaylist());
    }

    yield put(cleanEditAndCreateBiteState());
    yield put(setBites([data]));

    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    yield put(postOrUpdateBiteError());
    yield put(
      logError({
        event: 'postBiteSaga: error',
        error,
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });

    if (typeof onError === 'function') {
      onError();
    }
  }
}

function* updateBiteSaga(callback?: () => void, onError?: () => void) {
  const { selectedBite } = yield select(biteSelector);
  let {
    biteName,
    cover,
    cover_url,
    biteIcon,
    biteSettings,
    description,
    introMedia,
    linked_cover_url,
    introMediaProcessingStatus,
  }: ICreateBiteState = yield select(createBiteSelector);

  const data = cloneDeep({
    ...selectedBite,
    subject: biteName,
    linked_cover_url,
    description: description,
    cover_url,
    cover,
    icon: biteIcon?.id,
    ...biteSettings,
    bite_sections: [...selectedBite.bite_sections],
    extend: undefined,
  });

  const biteQuestion: BiteQuestionState = yield select(biteQuestionSelector);
  if (!biteQuestion.skipped) {
    const questionSection = data.bite_sections.find((section: TBiteSection) => section.type === 'question');
    questionSection.questions = [formatQuestionToServer(biteQuestion)];
    questionSection.media = biteQuestion.media ? { id: biteQuestion.media.id } : null;
  }

  const summaryCards: ISummaryCard[] = yield select(biteSummaryCardsSelector);

  /**
     special case for the quiz intro since the default value is already in the store
     on the other hand, the initial summary card object is not a valid value for the bite, so we have nothing to do inside the condition
     **/
  const isInitialEmptySummary = isEqual(summaryCards, summaryInitState.cards);

  if (summaryCards.length && !isInitialEmptySummary) {
    const summarySection = data.bite_sections.find((section: any) => section.type === 'summary');
    summarySection.summary_cards = formatSummaryCardsToServer(summaryCards);
  }

  const introSection = data.bite_sections.find((section: any) => section?.type === 'intro');
  if (introSection) {
    const taskId = introMediaProcessingStatus.all !== EIntroMediaProcessingStatus.INACTIVE && (yield waitForTaskId());
    if (taskId) {
      introSection.media = {};
      introSection.task_id = taskId;
      yield put(setIntroTask(null));
    } else if (introMedia) {
      introSection.media = {
        id: introMedia.id,
      };
      introSection.task_id = null;
    }
  }

  try {
    const res = yield call(calls.postAndUpdateBite, data);
    yield put(postOrUpdateBiteSuccess(res.data));

    yield put(cleanEditAndCreateBiteState());

    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    yield put(postOrUpdateBiteError());
    yield put(logError({ error }));
    if (typeof onError === 'function') {
      onError();
    }
  }
}

interface ICreateBiteSagaProps {
  processId?: string;
}
export function* createBiteSaga(body: Partial<IBiteItem> = {}, { processId }: ICreateBiteSagaProps = {}) {
  try {
    const org = yield select(activeOrganizationSelector);

    let newBite: Pick<IBiteItem, 'organization'> & Partial<IBiteItem> = {
      ...body,
      organization: org.id,
    };

    yield put(
      log({
        event: 'createBiteSaga',
        processId,
        data: {
          body,
          org,
          newBite,
        },
      }),
    );

    const { data } = yield withRetry(() => calls.createBite(newBite), {
      errorContext: {
        data: {
          action: 'createBiteSaga',
          newBite: cloneDeep(newBite),
        },
      },
    });

    yield put(
      log({
        event: 'createBiteSaga: createBite done',
        processId,
        data: {
          data,
        },
      }),
    );

    return data;
  } catch (error) {
    yield put(
      logError({
        event: 'createBiteSaga: error',
        processId,
        data: {
          error,
        },
      }),
    );

    throw error;
  }
}

function* deleteBiteIntroSaga({ payload: { biteId, callback } }: IAction<IEditBiteAction>) {
  try {
    yield call(calls.deleteBiteIntro, biteId);
    yield call(calls.resetBiteCover, biteId);
    yield put(indexBites({ biteIds: [biteId] }));

    const { selectedBite } = yield select(biteSelector);

    yield put(
      trackEvent({
        event: 'delete_story_section',
        props: { bite_id: biteId },
      }),
    );

    if (biteId !== selectedBite?.id) {
      return;
    }

    let selectedBiteCopy = cloneDeep(selectedBite);
    selectedBiteCopy.bite_sections = selectedBiteCopy.bite_sections.filter((item) => item.type !== 'intro');
    selectedBiteCopy.no_sections = !selectedBiteCopy.bite_sections.length;

    if (selectedBiteCopy.extend?.enhancements) {
      delete selectedBiteCopy.extend.enhancements;
    }
    if (selectedBiteCopy.extend?.subtitles) {
      delete selectedBiteCopy.extend.subtitles;
    }

    const payload = {
      ...formatBiteFromServer(selectedBiteCopy),
      selectedBite: selectedBiteCopy,
    };

    yield put(setBiteToEdit(payload));
    yield put(clearCreateBiteState());

    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    yield put(
      logError({
        event: 'deleteBiteIntroSaga: error',
        error,
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

function* deleteBiteQuestionSaga({ payload: { biteId, callback } }: IAction<IEditBiteAction>) {
  try {
    yield call(calls.deleteBiteQuestion, biteId);

    const { selectedBite } = yield select(biteSelector);

    yield put(
      trackEvent({
        event: 'delete_question_section',
        props: { bite_id: biteId },
      }),
    );

    if (biteId !== selectedBite?.id) {
      return;
    }

    let selectedBiteCopy = cloneDeep(selectedBite);
    selectedBiteCopy.bite_sections = selectedBiteCopy.bite_sections.filter((item) => item.type !== 'question');
    selectedBiteCopy.no_sections = !selectedBiteCopy.bite_sections.length;

    const payload = {
      ...formatBiteFromServer(selectedBiteCopy),
      selectedBite: selectedBiteCopy,
    };

    payload.questionState.isCreated = false;
    payload.questionState.isEdit = false;

    yield put(setBiteToEdit(payload));

    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    yield put(
      logError({
        event: 'deleteBiteQuestionSaga: error',
        error,
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

function* deleteBiteSummarySaga({ payload: { biteId, callback } }: IAction<IEditBiteAction>) {
  try {
    yield call(calls.deleteBiteSummary, biteId);
    yield put(indexBites({ biteIds: [biteId] }));

    const { selectedBite } = yield select(biteSelector);

    yield put(
      trackEvent({
        event: 'delete_summary_section',
        props: { bite_id: biteId },
      }),
    );

    if (biteId !== selectedBite?.id) {
      return;
    }

    let selectedBiteCopy = cloneDeep(selectedBite);
    selectedBiteCopy.bite_sections = selectedBiteCopy.bite_sections.filter((item) => item.type !== 'summary');
    selectedBiteCopy.no_sections = !selectedBiteCopy.bite_sections.length;

    const payload = {
      ...formatBiteFromServer(selectedBiteCopy),
      selectedBite: selectedBiteCopy,
    };

    yield put(setBiteToEdit(payload));
    yield put(setCreatedSummary(false));

    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    yield put(
      logError({
        event: 'deleteBiteSummarySaga: error',
        error,
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

function* editBiteQuestionSaga({ payload: { biteId, isPrefilled, callback } }: IAction<IEditBiteAction>) {
  try {
    const biteQuestion = yield select(biteQuestionSelector);
    const questionSection: QuestionSection = {
      type: 'question',
      media: biteQuestion.media ? { id: biteQuestion.media.id } : null,
      questions: [formatQuestionToServer(biteQuestion)],
    };

    if (biteId) {
      const { data: questionData } = yield call(calls.editBiteQuestion, biteId, questionSection);

      const { selectedBite } = yield select(biteSelector);

      yield put(
        trackEvent({
          event: 'save_question_section',
          props: { bite_id: selectedBite?.id },
        }),
      );

      if (biteId !== selectedBite?.id) {
        return;
      }

      let selectedBiteCopy = cloneDeep(selectedBite);
      selectedBiteCopy.bite_sections = [
        ...selectedBiteCopy.bite_sections.filter((item) => item.type !== questionData.type),
        questionData,
      ];
      selectedBiteCopy.no_sections = false;

      const payload = {
        ...formatBiteFromServer(selectedBiteCopy),
        selectedBite: selectedBiteCopy,
        createBiteState: null,
      };

      payload.questionState.isCreated = true;
      payload.questionState.isEdit = true;

      yield put(updateBiteSection({ biteId, section: questionData }));
      yield put(setBiteToEdit(payload));
    } else {
      const newBite = yield createBiteSaga();
      const newBiteId = newBite.id;

      yield put(setCurrentFlow({ biteId: newBiteId }));

      yield put(addToDrafts(newBiteId));

      const { data: questionData } = yield call(calls.editBiteQuestion, newBiteId, questionSection);

      let formattedBite;
      if (isPrefilled) {
        const { selectedBite } = yield select(biteSelector);
        const selectedBiteCopy = cloneDeep(selectedBite);
        selectedBiteCopy.bite_sections = [
          ...selectedBiteCopy.bite_sections.filter((item) => item.type !== 'question'),
          questionData,
        ];
        selectedBiteCopy.no_sections = false;

        formattedBite = {
          ...formatBiteFromServer({ ...selectedBiteCopy, id: newBite.id }),
          selectedBite: { ...selectedBiteCopy, id: newBite.id },
        };
      } else {
        newBite.bite_sections = [questionData];
        newBite.no_sections = false;

        formattedBite = {
          ...formatBiteFromServer(newBite),
          selectedBite: newBite,
        };
      }

      formattedBite.questionState.isCreated = true;
      formattedBite.questionState.isEdit = true;

      yield put(setBiteToEdit(formattedBite));
      yield put(setBites([newBite]));
      yield put(updateBiteSection({ biteId: newBiteId, section: questionData }));

      yield put(loadNextPage({ withBaseFiltersAndSorting: true }));
      yield put(setHasBites(true));
    }

    yield put(setBiteQuestionEdited(true));

    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    yield put(
      logError({
        event: 'editBiteQuestionSaga: error',
        error,
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

function* editBiteSummarySaga({ payload: { biteId, callback } }: IAction<IEditBiteAction>) {
  try {
    const summaryCards: ISummaryCard[] = yield select(biteSummaryCardsSelector);
    const summarySection = {
      type: 'summary',
      summary_cards: formatSummaryCardsToServer(summaryCards),
      media: null,
    };

    if (biteId) {
      const { data: summaryData } = yield call(calls.editBiteSummary, biteId, summarySection);

      const { selectedBite } = yield select(biteSelector);
      if (biteId !== selectedBite?.id) {
        return;
      }

      yield put(
        trackEvent({
          event: 'save_summary_section',
          props: { bite_id: selectedBite?.id },
        }),
      );

      let selectedBiteCopy = cloneDeep(selectedBite);
      selectedBiteCopy.bite_sections = [
        ...selectedBiteCopy.bite_sections.filter((item) => item.type !== summaryData.type),
        summaryData,
      ];
      selectedBiteCopy.no_sections = false;

      const payload = {
        ...formatBiteFromServer(selectedBiteCopy),
        selectedBite: selectedBiteCopy,
        createBiteState: null,
      };

      yield put(updateBiteSection({ biteId, section: summaryData }));
      yield put(setBiteToEdit(payload));
      yield put(setCreatedSummary(true));
      yield put(setBiteSummaryEdited(true));
      yield put(indexBites({ biteIds: [biteId] }));
    } else {
      const newBite = yield createBiteSaga();
      const newBiteId = newBite.id;

      yield put(setCurrentFlow({ biteId: newBiteId }));

      yield put(addToDrafts(newBiteId));

      const { data: summaryData } = yield call(calls.editBiteSummary, newBiteId, summarySection);

      newBite.bite_sections = [summaryData];
      newBite.no_sections = false;

      const formattedBite = {
        ...formatBiteFromServer(newBite),
        selectedBite: newBite,
      };

      yield put(setBites([newBite]));
      yield put(updateBiteSection({ biteId: newBiteId, section: summaryData }));
      yield put(setBiteToEdit(formattedBite));
      yield put(setCreatedSummary(true));

      yield put(loadNextPage({ withBaseFiltersAndSorting: true }));
      yield put(setHasBites(true));
      yield put(indexBites({ biteIds: [newBiteId] }));
    }

    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    yield put(
      logError({
        event: 'editBiteSummarySaga: error',
        error,
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

function* updateBiteDataSaga({ payload: { biteId, body, callback } }: IAction<IUpdateBiteAction>) {
  try {
    yield calls.updateBiteData(biteId, body);

    const { selectedBite } = yield select(biteSelector);
    if (biteId !== selectedBite?.id) {
      return;
    }

    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    yield put(
      logError({
        event: 'updateBiteDataSaga: error',
        error,
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

function* editBiteIntroMediaSaga({ payload: { biteId, bite, media, callback } }: IAction<IEditBiteIntroMediaAction>) {
  try {
    let newBiteId = biteId;

    if (biteId) {
      if (bite) {
        const { data } = yield call(calls.updateBiteData, biteId, bite);

        const formattedData = {
          ...formatBiteFromServer(data),
          selectedBite: data,
        };

        yield put(setBiteToEdit(formattedData));
      }

      yield put(setBiteIntro(media));
    } else {
      const org = yield select(activeOrganizationSelector);
      const { data } = yield call(calls.createBite, { ...bite, organization: org.id });
      newBiteId = data.id;

      yield put(setCurrentFlow({ biteId: newBiteId }));

      yield put(addToDrafts(newBiteId));

      const formattedData = {
        ...formatBiteFromServer(data),
        selectedBite: data,
      };

      yield put(loadNextPage({ withBaseFiltersAndSorting: true }));
      yield put(setHasBites(true));

      yield put(setBites([data]));
      yield put(setBiteToEdit(formattedData));
      yield put(setBiteIntro(media));
    }

    yield put(setBiteIntroEdited(true));
    const { data } = yield call(editBiteIntro, newBiteId, { media });

    yield put(updateBiteSection({ biteId, section: data }));
    yield put(setCreatedBiteIntroSection(data));
    yield put(updateSelectedBite({ no_sections: false }));

    const { selectedBite } = yield select(biteSelector);

    yield put(indexBites({ biteIds: [newBiteId] }));

    yield put(
      trackEvent({
        event: 'save_story_section',
        props: { bite_id: selectedBite?.id },
      }),
    );

    if (newBiteId !== selectedBite?.id) {
      return;
    }

    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    yield put(
      logError({
        event: 'editBiteIntroMediaSaga: error',
        error,
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

function* selectLocaleSaga({ payload: { taskId, locale, sourceLocale, onError } }: IAction<ISelectContentLocale>) {
  const processId = shortid();
  try {
    const subtitles = yield select(introSubtitlesSelector);
    const subtitlesByLocale = subtitles.find((item) => item.locale === locale);

    yield put(
      log({
        event: 'selectLocaleSaga',
        processId,
        data: {
          subtitles,
          subtitlesByLocale,
          taskId,
          locale,
          sourceLocale,
          onError: !!onError,
        },
      }),
    );

    if (subtitlesByLocale) {
      return;
    }

    yield calls.generateSubtitles({ taskId, locale, sourceLocale });

    yield put(
      log({
        event: 'selectLocaleSaga: generateSubtitles done',
        processId,
      }),
    );

    const user = yield select(userSelector);
    const { selectedBite } = yield select(biteSelector);
    const { counter: initialCounter }: IIntroMediaProcessingStatus = yield select(introMediaProcessingStatusSelector);
    const trackingOptions: ITrackingOptions = {
      bites_user_id: user.id,
      bite_id: selectedBite.id,
      task_id: null,
      feature: null,
      status: null,

      // s3
      s3_ts: null,

      // original
      original_processing_ts: null,
      enhancements_processing_ts: null,
      subtitles_processing_ts: null,

      // total
      total_ts: null,
    };
    const trackingMeta: ITrackingMeta = {
      biteId: selectedBite.id,
      startTs: {
        start: Date.now(),
        s3: Date.now(),
        processing_subtitles: Date.now(),
        processing_enhancements: Date.now(),
      },
      status: {
        all: EIntroMediaProcessingStatus.PROCESSING,
        audioExport: EIntroMediaProcessingStatus.INACTIVE,
        videoExport: EIntroMediaProcessingStatus.INACTIVE,
        s3: EIntroMediaProcessingStatus.INACTIVE,
        original: EIntroMediaProcessingStatus.INACTIVE,
        enhancements: EIntroMediaProcessingStatus.INACTIVE,
        subtitles: EIntroMediaProcessingStatus.PROCESSING,
        summarySuggestion: EIntroMediaProcessingStatus.INACTIVE,
        questionSuggestion: EIntroMediaProcessingStatus.INACTIVE,
        biteNameSuggestion: EIntroMediaProcessingStatus.INACTIVE,
        coverSuggestion: EIntroMediaProcessingStatus.INACTIVE,
      },
    };

    yield put(setIntroTask(taskId));
    yield trackIntroMediaProcessingSubtitles({
      processId,
      initialTaskId: taskId,
      initialCounter,
      trackingOptions,
      trackingMeta,
      targetLocale: locale,
      onError,
    });
  } catch (error) {
    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });

    yield put(
      logError({
        event: 'selectLocaleSaga: error',
        processId,
        data: {
          error,
          response: error?.response,
        },
      }),
    );
    if (typeof onError === 'function') {
      onError();
    }
  }
}

function* setSubtitlesApprovedStateSaga({
  payload: { id, approved, onSuccess, onError },
}: IAction<{ id: number; approved: boolean; onSuccess: (state: boolean) => void; onError: () => void }>) {
  yield put(
    log({
      event: 'setSubtitlesApprovedStateSaga: start',
      data: { id, approved },
    }),
  );
  try {
    const { enabled } = yield select(introSubtitlesLinesSelector);
    const { data } = yield calls.updateSubtitlesState({ subtitlesId: id, enabled, approved });
    yield put(
      log({
        event: 'setSubtitlesApprovedStateSaga: done',
        data: { id, approved, response: data },
      }),
    );

    yield put(saveSubtitlesApprovedStateSuccess({ id, approved: data.subtitles.approved }));

    if (typeof onSuccess === 'function') {
      onSuccess(data.subtitles.approved);
    }
  } catch (error) {
    yield put(
      logError({
        event: 'selectLocaleSaga: error',
        data: {
          error,
          response: error?.response,
        },
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });

    if (typeof onError === 'function') {
      onError();
    }
  }
}

export function* createBiteSagaWatcher() {
  yield all([
    takeLatest(Types.UPDATE_BITE_DATA, updateBiteDataSaga),
    takeLatest(Types.EDIT_BITE_INTRO_MEDIA, editBiteIntroMediaSaga),
    takeLatest(Types.EDIT_BITE_SUMMARY, editBiteSummarySaga),
    takeLatest(Types.EDIT_BITE_QUESTION, editBiteQuestionSaga),
    takeLatest(Types.DELETE_BITE_INTRO, deleteBiteIntroSaga),
    takeLatest(Types.DELETE_BITE_SUMMARY, deleteBiteSummarySaga),
    takeLatest(Types.DELETE_BITE_QUESTION, deleteBiteQuestionSaga),
    takeLatest(Types.SET_BITE_INTRO_UPLOADING, uploadVideoSaga),
    takeLatest(Types.FETCH_INTRO_SUBTITLES_LINES, fetchIntroSubtitlesLines),
    takeLatest(Types.SAVE_INTRO_ENHANCEMETS_CHANGES, saveIntroEnhancementsChanges),
    takeLatest(Types.SAVE_INTRO_SUBTITLES_LINES, saveIntroSubtitlesLinesSaga),
    takeLatest(Types.POST_OR_UPDATE_QUIZ_BITE_REQUEST, postAndUpdateQuizBite),
    takeLatest(Types.SELECT_LOCALE, selectLocaleSaga),
    takeLatest(Types.SAVE_SUBTITLES_APPROVED_STATE, setSubtitlesApprovedStateSaga),
  ]);
}
