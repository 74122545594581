import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { calcFontSize, calcHeight } from '../../../utils/dimensions';
import Dropzone, { FileWithPath } from 'react-dropzone';
import { formatFileTypesForWeb } from '../../../hooks/useMedia';
import defaultTheme from '../../../themes/defaultTheme';
import { useDispatch, useSelector } from 'react-redux';
import { createCloudAsset } from '../../../store/cloudAssets/cloudAssets.slice';
import {
  ICloudAssetAudioCache,
  ICloudAssetVideoCache,
  TCloudAssetCache,
  cloneCloudAssetCacheForAudioTimelineItem,
  cloneCloudAssetCacheForVideoTimelineItem,
} from '../../../store/cloudAssets/cloudAssets.cache';
import { ICloudAsset } from '../../../store/cloudAssets/cloudAssets.types';
import { getVideoMetadata } from '../../../utils/getVideoMetadata';
import { getImageMetadata } from '../../../utils/getImageMetadata';
import { getAudioMetadata } from '../../../utils/getAudioMetadata';
import {
  addToTimeline,
  addUndo,
  deleteProcessingTimelineItemState,
  removeEmptyTimelineLayers,
  removeUndoRedoById,
  saveVideo,
  setProcessingTimelineItemState,
  updateCanvas,
  updateTimelineItem,
  updateTimelineItemLayer,
  updateTimelineItemWithCloudAssetDuration,
} from '../../../store/videoEditor/videoEditor.slice';
import { getItemCanvasSize } from '../utils/getItemCanvasSize';
import { IImageFileMetadata, IVideoFileMetadata } from '../../../types/typings';
import { videoResolutionSeletor } from '../../../store/videoEditor/videoEditor.selectors';
import { v4 as uuid } from 'uuid';
import { retryFunctions } from '../../../store/videoEditor/videoEditor.data';
import { getIsItemOnTimeline } from '../utils/getIsItemOnTimeline';
import { MAX_ASSET_FILE_SIZE_MB } from '../videoEditor.constants';
import { logError } from '../../../store/appActivity/appActivity.slice';
import { getUrlExtension } from '../../../utils/getUrlExtension';
import { getVideoThumbnail } from '../../../utils/introMedia/useVideoThumbnail/useVideoThumbnail.web';

const acceptedExtensions = {
  video: ['.avi', '.mp4', '.mpeg', '.ogv', '.ts', '.webm', '.3gp', '.3g2', '.mov'],
  image: [
    '.png',
    '.jpg',
    '.jpeg',
    '.webp',
    // '.tif', '.tiff' - fails to load the image
  ],
  audio: ['.aac', '.m4a', '.mp3', '.opus', '.webm', '.wav', '.3g2', '.3gp'],
};
const acceptedFileTypes = formatFileTypesForWeb(acceptedExtensions);
const getIsAllowedFileType = (file: FileWithPath) => {
  const extension = getUrlExtension(file.name);
  const extensionFormatted = `.${extension}`.toLowerCase();
  // verify all accepted extensions
  for (const type in acceptedExtensions) {
    if (acceptedExtensions[type].includes(extensionFormatted)) {
      return true;
    }
  }
  return false;
};

type Props = {
  isBrandAsset?: boolean;
};
const FileUpload = ({ isBrandAsset = false }: Props) => {
  const dispatch = useDispatch();

  const resolution = useSelector(videoResolutionSeletor);

  const webUpload = useRef(null);

  const handleFileSelect = useCallback(
    async (files: FileWithPath[]) => {
      files.forEach((file) => {
        const isAllowedFileType = getIsAllowedFileType(file);
        if (!isAllowedFileType) {
          return;
        }

        const retryFunctionId = uuid();
        const timelineItemId = uuid();

        const processingLabel = file.name || 'File';

        const handelRetry = (error: any) => {
          dispatch(
            setProcessingTimelineItemState({
              timelineItemId,
              label: processingLabel,
              isProcessing: false,
              isDone: false,
              retryFunctionId,
              error: {
                details: error,
              },
            }),
          );
        };

        const applyItemFunction = async () => {
          try {
            dispatch(
              setProcessingTimelineItemState({
                timelineItemId,
                label: processingLabel,
                isProcessing: true,
                isDone: false,
                retryFunctionId,
              }),
            );

            const type = file.type?.split('/')[0];
            let fileType: ICloudAsset['fileType'] =
              type === 'video'
                ? 'video'
                : type === 'image'
                ? 'image'
                : type === 'audio'
                ? 'audio'
                : // : file.type === 'application/pdf'
                  // ? 'pdf'
                  // : file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                  //   file.type === 'application/vnd.ms-powerpoint'
                  // ? 'ppt'
                  // : file.type === 'application/msword' ||
                  //   file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  // ? 'doc'
                  null;

            if (!fileType) {
              dispatch(
                logError({
                  event: 'FileUpload.handleFileSelect: unrecognized file type',
                  data: {
                    fileType: file.type,
                  },
                }),
              );
              return;
            }

            if (file?.type === 'image/gif') {
              fileType = 'gif';
            }

            const fileMeta =
              fileType === 'video'
                ? await getVideoMetadata({ file })
                : fileType === 'audio'
                ? await getAudioMetadata({ file })
                : fileType === 'image' || fileType === 'gif'
                ? await getImageMetadata({ file })
                : null;

            const fileMetaVideo = fileMeta as IVideoFileMetadata;
            if (fileType === 'video' && fileMetaVideo.duration && (!fileMetaVideo.width || !fileMetaVideo.height)) {
              fileType = 'audio';
            }

            dispatch(
              addToTimeline({
                timelineItem: {
                  id: timelineItemId,
                  type: fileType,
                  x: 0,
                  y: 0,
                },
              }),
            );
            dispatch(
              updateTimelineItemLayer({
                timelineItemId,
              }),
            );
            dispatch(removeEmptyTimelineLayers());

            const undoId = uuid();
            dispatch(
              addUndo({
                id: undoId,
              }),
            );

            const retryFunction = async () => {
              try {
                const sizeMb = Math.round(file.size / 1024 / 1024);

                if (sizeMb > MAX_ASSET_FILE_SIZE_MB) {
                  dispatch(
                    setProcessingTimelineItemState({
                      timelineItemId,
                      label: processingLabel,
                      isProcessing: false,
                      isDone: false,
                      retryFunctionId,
                      error: {
                        code: 'FILE_SIZE_LIMIT_EXCEEDED',
                        details: {
                          sizeMb,
                        },
                      },
                    }),
                  );
                  return;
                }

                dispatch(
                  setProcessingTimelineItemState({
                    timelineItemId,
                    label: processingLabel,
                    isProcessing: true,
                    isDone: false,
                    retryFunctionId,
                  }),
                );

                const previewImageFile: File | null = fileType === 'video' ? await getVideoThumbnail(file) : null;

                const { cloudAsset, cloudAssetCache } = await new Promise<{
                  cloudAsset: ICloudAsset;
                  cloudAssetCache: TCloudAssetCache;
                }>(async (resolve, reject) => {
                  dispatch(
                    createCloudAsset({
                      fileType,
                      file,
                      fileMeta,
                      originalSrc: 'local',
                      originalData: {
                        name: file.name,
                        size: file.size,
                        type: file.type,
                      },
                      previewImageFile,
                      isBrandAsset: isBrandAsset || undefined,
                      onCacheReady: resolve,
                      onFail: reject,
                    }),
                  );
                });

                if (cloudAsset.fileType === 'video') {
                  await cloneCloudAssetCacheForVideoTimelineItem(
                    cloudAssetCache as ICloudAssetVideoCache,
                    timelineItemId,
                  );
                }
                if (cloudAsset.fileType === 'audio') {
                  await cloneCloudAssetCacheForAudioTimelineItem(
                    cloudAssetCache as ICloudAssetAudioCache,
                    timelineItemId,
                  );
                }

                dispatch(
                  removeUndoRedoById({
                    id: undoId,
                  }),
                );

                dispatch(
                  setProcessingTimelineItemState({
                    timelineItemId,
                    label: processingLabel,
                    isProcessing: false,
                    isDone: true,
                  }),
                );
                setTimeout(() => {
                  dispatch(
                    deleteProcessingTimelineItemState({
                      timelineItemId,
                    }),
                  );
                }, 5000);

                const isOnTimeline = getIsItemOnTimeline(timelineItemId);

                if (!isOnTimeline) {
                  return;
                }

                const updateTimelineItemPayload: any = {
                  id: timelineItemId,
                  cloudAssetId: cloudAsset.id,
                };

                if (fileType !== 'audio') {
                  const visualFileMeta = fileMeta as IImageFileMetadata;

                  const canvasSize = getItemCanvasSize({
                    width: visualFileMeta.width,
                    height: visualFileMeta.height,
                    resolution,
                    scaleUp: true,
                  });

                  updateTimelineItemPayload.width = canvasSize.width;
                  updateTimelineItemPayload.height = canvasSize.height;
                }

                dispatch(updateTimelineItem(updateTimelineItemPayload));

                if (fileType === 'video' || fileType === 'audio') {
                  dispatch(
                    updateTimelineItemWithCloudAssetDuration({
                      timelineItemId,
                    }),
                  );
                }

                dispatch(updateCanvas({}));
                dispatch(
                  addUndo({
                    id: undoId,
                  }),
                );
                dispatch(saveVideo({}));
              } catch (error) {
                handelRetry(error);
              }
            };

            retryFunctions[retryFunctionId] = retryFunction;
            retryFunction();
          } catch (error) {
            handelRetry(error);
          }
        };

        retryFunctions[retryFunctionId] = applyItemFunction;
        applyItemFunction();
      });
    },
    [dispatch, isBrandAsset, resolution],
  );

  return (
    <>
      <Dropzone ref={webUpload} onDrop={handleFileSelect} accept={acceptedFileTypes}>
        {({ getRootProps, getInputProps }) => (
          <S.DropzoneUI {...getRootProps()}>
            Drag and drop files here or click to open the file browser
            <S.DropzoneInput {...getInputProps()} />
          </S.DropzoneUI>
        )}
      </Dropzone>
    </>
  );
};
export default FileUpload;

const S = {
  DropzoneUI: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin: ${calcHeight(4)}px ${calcHeight(16)}px ${calcHeight(16)}px;
    padding: ${calcHeight(16)}px;
    color: ${defaultTheme.colors.gray1};
    font-size: ${calcFontSize(14)}px;
    font-weight: 500;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    text-align: center;
    border: 2px dashed ${defaultTheme.colors.blue6};
    border-radius: 10px;
    cursor: pointer;
  `,
  DropzoneInput: styled.input`
    display: none;
  `,
};
