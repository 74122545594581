import { all, takeLatest, takeEvery, put, call, select, delay, spawn, take } from 'redux-saga/effects';
import Types from './bite.types';
import {
  biteInProgressSelector,
  biteItemSelector,
  biteSelector,
  selectedBiteSelector,
} from '../../store/bite/bite.selectors';
import * as calls from '../api/bites-api/calls/bite.calls';
import {
  setBiteToEdit,
  setBiteLoading,
  setBites,
  setHasBites,
  setBiteInProgress,
  cleanEditAndCreateBiteState,
  setBitesLoadingState,
  setSelectedBiteIntroVideo,
} from './bite.actions';
import {
  clearCreateBiteState,
  resetEditedSections,
  startIntroMediaProcessing,
} from '../createBite/createBites.actions';
import { IBiteItem, IntroSection, ICopyBiteAction } from '../../types/bite';
import { formatBiteFromServer } from '../../utils/formatDataFromServer';

import { EIntroMediaProcessingStatus } from '../createBite/createBite.types';
import { EditBiteIconType } from '../../screens/editBite/EditMain/common/SectionItem';
import { createBiteSelector } from '../createBite/createBite.selectors';
import getBiteUploadingMediaStatus from '../../utils/introMedia/getUploadingIntroMediaStatus';
import { activeOrganizationSelector, organizationsListSelector, userSelector } from '../auth/auth.selectors';
import { loadBiteHelperConfigs, log, logError, trackEvent } from '../appActivity/appActivity.slice';
import { navigate } from '../../navigation/RootNavigation';
import Routes from '../../navigation/routes';
import { loadNextPageSaga } from '../feed/feed.saga';
import { setHomeActiveTab } from '../homeScreen/homeScreen.slice';
import { Tabs } from '../homeScreen/homeScreen.types';
import { copyBite } from '../api/bites-api/calls/explore.calls';
import Toast from 'react-native-toast-message';
import { EToastTypes } from '../../utils/constants/toastConfig';
import { removeFromDrafts } from '../drafts/drafts.slice';
import getOrganizations from '../../utils/getOrganizations';
import uploadCover from '../../utils/uploadImage/uploadImage';
import { updateBiteData } from '../api/bites-api/calls/bite.calls';
import { isWeb } from '../../utils/dimensions';
import { v4 as getUUID } from 'uuid';
import {
  EInProgressStatus,
  EInProgressTask,
  IItemInProgressSagaPayload,
  IOnAttributesPayload,
  IUploadBiteCover,
} from '../../types/common';
import { loadNextPage } from '../feed/feed.slice';
import { setCreatedSummary } from '../biteSummary/biteSummary.actions';
import { setCreatedQuestion } from '../biteQuestion/biteQuestion.actions';
import {
  organizationAttributesErrorSelector,
  organizationAttributesIsLoadingSelector,
  organizationAttributesSelector,
} from '../org/org.selectors';
import { fetchOrgSuccess, getOrgAttributes, switchOrganization, waitForAttributes } from '../org/org.slice';
import store from '..';
import { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import withRetry from '../../utils/withRetry';
import { getVideoService } from '../api/bites-api/calls/videoEditor.calls';
import { getErrorLogData } from '../../utils/getErrorLogData';

export function* fetchHasBitesSaga() {
  const org = yield select(activeOrganizationSelector);
  const { data } = yield calls.fetchBites(org.id, {
    isWithoutEmptyBites: true,
    pageSize: 1,
    page: 1,
  });
  const hasBites = data.results.length > 0;
  yield put(setHasBites(hasBites));
}

export function* fetchFullBitesSaga({ payload }: Partial<PayloadAction<number[]>>) {
  try {
    // get list with statistics
    const promises = payload.map(async (biteId) => {
      try {
        const { data } = await calls.fetchBite(biteId, {
          extend: ['enhancements', 'subtitles'],
        });
        return { data };
      } catch (error) {
        store.dispatch(
          logError({
            event: 'fetchFullBitesSaga: error',
            data: {
              biteId,
              error,
            },
          }),
        );
        return {
          data: { id: biteId },
          error: error.message,
        };
      }
    });

    const results = yield Promise.all(promises);
    const bitesLoadingState = results.map(({ data, error }) => {
      return {
        biteId: data.id,
        isLoading: false,
        error,
      };
    });

    yield put(setBitesLoadingState(bitesLoadingState));
    const bites: IBiteItem[] = results.filter(({ data, error }) => data && !error).map(({ data }) => data);
    yield put(setBites(bites));

    return bites;
  } catch (error) {
    yield put(
      logError({
        event: 'fetchFullBitesSaga: error',
        error,
      }),
    );
  }
}

export function* fetchFullBitesWithCallbackSaga({
  payload,
}: Partial<
  PayloadAction<{
    biteIds: number[];
    onSuccess?: (props: { bites: IBiteItem[] }) => void;
    onError?: () => void;
  }>
>) {
  const { biteIds, onSuccess, onError } = payload;
  try {
    const bites = yield fetchFullBitesSaga({ payload: biteIds });

    onSuccess?.({ bites });
  } catch (error) {
    yield put(
      logError({
        event: 'fetchFullBitesWithCallbackSaga: error',
        error: getErrorLogData(error),
      }),
    );

    onError?.();
  }
}

function* cloneAndFetchSaga({
  payload: { biteId, callback },
}: Partial<PayloadAction<{ biteId: number; callback?: () => void }>>) {
  try {
    yield put(loadBiteHelperConfigs());
    const activeOrganization = yield select(activeOrganizationSelector);
    const user = yield select(userSelector);
    const {
      data: { clone_id },
    } = yield copyBite({
      bite: biteId,
      organization: activeOrganization.id,
      owner: user.id,
    });

    const { data } = yield calls.fetchBite(clone_id, {
      extend: ['enhancements', 'subtitles'],
    });

    const payload = {
      ...formatBiteFromServer(data),
      selectedBite: data,
    };

    yield put(clearCreateBiteState());
    yield put(cleanEditAndCreateBiteState());

    yield put(setBiteToEdit(payload));
    yield put(setHomeActiveTab(Tabs.FEED));
    yield put(loadNextPage({ withBaseFiltersAndSorting: true }));
    yield put(setCreatedSummary(data.bite_sections.findIndex((section) => section.type === 'summary') !== -1));
    yield put(setCreatedQuestion(data.bite_sections.findIndex((section) => section.type === 'question') !== -1));

    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    yield put(
      logError({
        event: 'cloneAndFetchSaga: error',
        error,
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

function* fetchBiteToEditSaga({
  payload: { biteId, callback },
}: Partial<PayloadAction<{ biteId: number; callback?: () => void }>>) {
  try {
    const { data } = yield calls.fetchBite(biteId, {
      extend: ['enhancements', 'subtitles'],
    });
    const payload = {
      ...formatBiteFromServer(data),
      selectedBite: data,
    };

    yield put(setBiteToEdit(payload));

    yield spawn(getSelectedBiteIntroVideoSaga);

    if (typeof callback === 'function') {
      callback();
    }
  } catch (error: any) {
    yield put(
      logError({
        event: 'fetchBiteToEditSaga: error',
        data: {
          errorMessage: error?.message,
          errorStack: error?.stack,
        },
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

function* getSelectedBiteIntroVideoSaga() {
  const processId = uuid();

  yield put(
    log({
      event: 'getSelectedBiteIntroVideoSaga',
      processId,
    }),
  );

  try {
    const selectedBite = yield select(selectedBiteSelector);

    yield put(
      setSelectedBiteIntroVideo({
        video: null,
        isLoading: true,
        error: false,
      }),
    );

    const {
      data: { video },
    } = yield withRetry(
      () =>
        getVideoService({
          usedInBiteId: selectedBite.id,
        }),
      {
        errorContext: {
          action: 'getBiteVideoService',
          processId,
        },
      },
    );

    const currentSelectedBite = yield select(selectedBiteSelector);

    if (currentSelectedBite.id !== selectedBite.id) {
      return;
    }

    yield put(
      log({
        event: 'getSelectedBiteIntroVideoSaga: done',
        processId,
        data: {
          video,
        },
      }),
    );

    yield put(
      setSelectedBiteIntroVideo({
        video,
        isLoading: false,
        error: false,
      }),
    );
  } catch (error: any) {
    yield put(
      logError({
        event: 'getSelectedBiteIntroVideoSaga: error',
        processId,
        data: {
          errorMessage: error?.message,
          errorStack: error?.stack,
        },
      }),
    );

    yield put(
      setSelectedBiteIntroVideo({
        video: null,
        isLoading: false,
        error,
      }),
    );
  }
}

function* deleteBiteSaga({ payload: biteId }: PayloadAction<number>) {
  try {
    yield call(calls.deleteBite, biteId);
    yield spawn(indexBitesSaga, { payload: { biteIds: [biteId] }, type: Types.INDEX_BITES });
    yield fetchHasBitesSaga();
  } catch (err) {
    console.log('ERROR deleteBiteSaga', err);
  }
}

export function* indexBitesSaga({ payload: { biteIds } }: PayloadAction<{ biteIds: number[] }>) {
  try {
    yield calls.reindexBites({ biteIds });
  } catch (err) {
    console.error({
      event: 'indexBitesSaga: error',
      error: err,
    });
  }
}

function* prepareBiteEditScreenSaga({ payload: { biteId } }: PayloadAction<{ biteId: number }>) {
  try {
    yield put(removeFromDrafts(biteId));
    yield put(setBiteLoading(true));

    yield put(resetEditedSections());
    yield put(clearCreateBiteState());
    yield fetchBiteToEditSaga({ payload: { biteId } });

    yield put(setBiteLoading(false));

    const { selectedBite } = yield select(biteSelector);
    const { taskId } = yield select(createBiteSelector);

    const introSection = selectedBite?.bite_sections.find(
      ({ type }) => type === EditBiteIconType.INTRO,
    ) as IntroSection;

    if (!introSection) {
      return;
    }

    const uploadingMediaStatus = getBiteUploadingMediaStatus(selectedBite);
    const isNewTaskId = introSection?.task_id && introSection.task_id !== taskId;
    const isUploadMediaStatusProcessing =
      uploadingMediaStatus === EIntroMediaProcessingStatus.PROCESSING ||
      uploadingMediaStatus === EIntroMediaProcessingStatus.PARTIAL_SUCCESS;

    if (isUploadMediaStatusProcessing && isNewTaskId) {
      yield put(startIntroMediaProcessing({ taskId: introSection.task_id }));
    }
  } catch (error: any) {
    yield put(
      logError({
        event: 'prepareBiteEditScreenSaga: error',
        data: {
          errorMessage: error?.message,
          errorStack: error?.stack,
        },
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

function* setBiteInProgressSaga(payload: IItemInProgressSagaPayload) {
  const taskId = payload.taskId;
  let { overwriteTaskId, withResetTimeout, ...taskDetails } = payload;

  if (!overwriteTaskId) {
    const { taskId: currentTaskId } = yield select(biteInProgressSelector(payload.itemId, payload.task));
    if (currentTaskId !== taskId) {
      return false;
    }
  }

  yield put(setBiteInProgress(taskDetails));

  if (withResetTimeout) {
    yield spawn(resetBiteInProgressSaga, { withResetTimeout, ...taskDetails });
  }

  return true;
}

function* resetBiteInProgressSaga(payload: IItemInProgressSagaPayload) {
  const taskId = payload.taskId;
  let { withResetTimeout, ...taskDetails } = payload;

  withResetTimeout = withResetTimeout === true ? 5000 : (withResetTimeout as number);

  yield delay(withResetTimeout);

  const { taskId: currentTaskId } = yield select(biteInProgressSelector(taskDetails.itemId, taskDetails.task));
  if (currentTaskId !== taskId) {
    return false;
  }

  yield put(
    setBiteInProgress({
      itemId: taskDetails.itemId,
      task: taskDetails.task,
      taskId: taskDetails.taskId,
      status: null,
    }),
  );
}

function* copyBiteRequestSaga({ payload: { bite, org } }: PayloadAction<ICopyBiteAction>) {
  yield put(loadBiteHelperConfigs());
  const itemId = bite.id;
  const taskDetails = {
    itemId,
    task: EInProgressTask.CLONE,
    taskId: getUUID(),
  };

  try {
    yield setBiteInProgressSaga({
      ...taskDetails,
      status: EInProgressStatus.IN_PROGRESS,
      overwriteTaskId: true,
    });

    const user = yield select(userSelector);
    const activeOrg = yield select(activeOrganizationSelector);

    const isCurrentWorkspace = org.id === activeOrg.id;
    const creatorOrganizations = getOrganizations(user, { isCreator: true });

    const { data } = yield copyBite({
      bite: itemId,
      cloned_bite_name: isCurrentWorkspace ? `Copy of ${bite.subject}` : `${bite.subject}`,
      organization: org.id,
      owner: user.id,
    });

    const isSameTask = yield setBiteInProgressSaga({
      ...taskDetails,
      status: EInProgressStatus.SUCCESS,
      withResetTimeout: true,
    });
    if (!isSameTask) {
      return;
    }

    yield put(
      trackEvent({
        event: 'clone',
        props: { bite_id: data.clone_id, inspiration_bite: false },
      }),
    );

    if (creatorOrganizations.length === 1) {
      navigate(Routes.HomeStack.StackName, {
        screen: Routes.HomeStack.Home,
      });
    }

    yield spawn(loadNextPageSaga, { payload: { reset: true } });
    yield put(setHomeActiveTab(Tabs.FEED));

    Toast.show({
      type: EToastTypes.copySuccess,
      props: {
        biteName: bite?.subject,
        workspaceName: !isCurrentWorkspace && org?.name,
      },
    });
  } catch (error) {
    const isSameTask = yield setBiteInProgressSaga({
      ...taskDetails,
      status: EInProgressStatus.ERROR,
      withResetTimeout: true,
    });
    if (!isSameTask) {
      return;
    }

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
    yield put(
      logError({
        event: 'copyBiteRequestSaga: error',
        error,
      }),
    );
  }
}

function* updateBiteCoverSaga({ payload: { biteId, uri } }: Partial<PayloadAction<IUploadBiteCover>>) {
  const taskDetails = {
    itemId: biteId,
    task: EInProgressTask.COVER,
    taskId: getUUID(),
  };
  try {
    yield setBiteInProgressSaga({
      ...taskDetails,
      status: EInProgressStatus.IN_PROGRESS,
      overwriteTaskId: true,
    });

    yield updateBiteData(biteId, {
      linked_cover_url: uri,
    });

    const isSameTask = yield setBiteInProgressSaga({
      ...taskDetails,
      status: EInProgressStatus.SUCCESS,
      withResetTimeout: true,
    });

    if (!isSameTask) {
      return;
    }

    yield put(
      setBites([
        {
          id: biteId,
          cover_url: uri,
          linked_cover_url: uri,
        },
      ]),
    );
  } catch (error) {
    yield put(
      logError({
        event: 'updateBiteCoverSaga: error',
        error,
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });

    yield setBiteInProgressSaga({
      ...taskDetails,
      status: EInProgressStatus.ERROR,
      withResetTimeout: true,
    });
  }
}

function* uploadBiteCoverImageSaga({ payload: { biteId, uri, type = '' } }: PayloadAction<IUploadBiteCover>) {
  const taskDetails = {
    itemId: biteId,
    task: EInProgressTask.COVER,
    taskId: getUUID(),
  };
  try {
    yield setBiteInProgressSaga({
      ...taskDetails,
      status: EInProgressStatus.IN_PROGRESS,
      overwriteTaskId: true,
    });

    const {
      data: { id, image },
    } = yield uploadCover(
      isWeb
        ? {
            file: uri,
            path: 'upload_cover',
          }
        : {
            uri: uri,
            type,
            path: 'upload_cover',
          },
    );

    const isBeforeUpdateSameTask = yield setBiteInProgressSaga({
      ...taskDetails,
      status: EInProgressStatus.IN_PROGRESS,
    });

    if (!isBeforeUpdateSameTask) {
      return false;
    }

    yield updateBiteData(biteId, {
      cover: id,
      linked_cover_url: image,
    });

    const isSameTask = yield setBiteInProgressSaga({
      ...taskDetails,
      status: EInProgressStatus.SUCCESS,
      withResetTimeout: true,
    });

    if (!isSameTask) {
      return;
    }

    yield put(
      setBites([
        {
          id: biteId,
          cover_url: image,
          linked_cover_url: image,
        },
      ]),
    );
  } catch (error) {
    yield put(
      logError({
        event: 'uploadBiteCoverImageSaga: error',
        error,
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });

    yield setBiteInProgressSaga({
      ...taskDetails,
      status: EInProgressStatus.ERROR,
      withResetTimeout: true,
    });
  }
}

function* onAttributesSaga({ payload }: PayloadAction<IOnAttributesPayload>) {
  const { biteId, callback } = payload;
  const activeOrganization = yield select(activeOrganizationSelector);

  let bite = yield select(biteItemSelector(biteId));

  if (!bite?.organization) {
    yield fetchFullBitesSaga({ payload: [biteId] });
    bite = yield select(biteItemSelector(biteId));
  }

  if (!bite?.organization) {
    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
    return;
  }

  if (bite.organization !== activeOrganization.id) {
    const organizationsList = yield select(organizationsListSelector);
    const newActiveOrganization = organizationsList?.find((org) => org.id === bite.organization);
    yield put(switchOrganization({ organization: newActiveOrganization, isSetDefault: false }));
    yield take(fetchOrgSuccess);
    yield put(setBites([bite]));
  }

  const organizationAttributes = yield select(organizationAttributesSelector);
  const attributesLoading = yield select(organizationAttributesIsLoadingSelector);
  const attributesError = yield select(organizationAttributesErrorSelector);

  const runCallback = (attributes = organizationAttributes) => {
    if (typeof callback !== 'function') {
      return;
    }

    callback({ attributes });
  };

  if (attributesError) {
    yield put(
      getOrgAttributes({
        organizationId: bite.organization,
        onSuccess: runCallback,
        onError: () =>
          Toast.show({
            type: EToastTypes.networkError,
            topOffset: 0,
          }),
      }),
    );
    return;
  }

  if (attributesLoading) {
    yield put(
      waitForAttributes({
        onSuccess: runCallback,
        onError: () =>
          Toast.show({
            type: EToastTypes.networkError,
            topOffset: 0,
          }),
      }),
    );
    return;
  }

  runCallback();
}

function* updateBiteDataSaga({
  payload: { biteId, body },
}: PayloadAction<{ biteId: number; body: Partial<IBiteItem> }>) {
  try {
    const { data } = yield call(calls.updateBiteData, biteId, body);
    yield put(setBites([data]));
  } catch (error) {
    yield put(
      logError({
        event: 'updateBiteDataSaga: error',
        data: error,
      }),
    );
  }
}

function* triggerCreatedBiteTransactionalCommunicationSaga({ payload: { biteId } }: PayloadAction<{ biteId: number }>) {
  try {
    yield withRetry(() => calls.transactionalBiteCreated({ biteId }), {
      errorContext: {
        action: 'triggerCreatedBiteTransactionalCommunicationSaga',
      },
    });
  } catch (error) {
    yield put(
      logError({
        event: 'triggerCreatedBiteTransactionalCommunicationSaga: error',
        data: error,
      }),
    );
  }
}

export default function* biteSagaWatcher() {
  yield all([
    takeEvery(Types.UPDATE_BITE_COVER, updateBiteCoverSaga),
    takeEvery(Types.UPLOAD_BITE_COVER_IMAGE, uploadBiteCoverImageSaga),
    takeLatest(Types.FETCH_HAS_BITES, fetchHasBitesSaga),
    takeEvery(Types.FETCH_FULL_BITES, fetchFullBitesSaga),
    takeEvery(Types.FETCH_FULL_BITES_WITH_CALLBACK, fetchFullBitesWithCallbackSaga),
    takeLatest(Types.COPY_BITE, copyBiteRequestSaga),
    takeLatest(Types.FETCH_BITE_TO_EDIT_REQUEST, fetchBiteToEditSaga),
    takeLatest(Types.DELETE_BITE, deleteBiteSaga),
    takeLatest(Types.PREPARE_BITE_EDIT_SCREEN, prepareBiteEditScreenSaga),
    takeLatest(Types.CLONE_AND_FETCH, cloneAndFetchSaga),
    takeLatest(Types.ON_ATTRIBUTES, onAttributesSaga),
    takeLatest(Types.PATCH_BITE_DATA, updateBiteDataSaga),
    takeLatest(Types.INDEX_BITES, indexBitesSaga),
    takeLatest(
      Types.TRIGGER_CREATED_BITE_TRANSACTIONAL_COMMUNICATION,
      triggerCreatedBiteTransactionalCommunicationSaga,
    ),
  ]);
}
