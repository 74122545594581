import { IBiteItem } from '../../types/bite';
import { IItemInProgress } from '../../types/common';
import { IVideo } from '../videoEditor/videoEditor.types';

const Types = {
  SET_HAS_BITES: 'SET_HAS_BITES',
  FETCH_HAS_BITES: 'FETCH_HAS_BITES',
  FETCH_BITE_TO_EDIT_REQUEST: 'FETCH_BITE_TO_EDIT_REQUEST',
  FETCH_BITE_GROUP_SHARE_REQUEST: 'FETCH_BITE_GROUP_SHARE_REQUEST',
  SET_BITE_TO_EDIT: 'SET_BITE_TO_EDIT',
  SET_SELECTED_BITE_INTRO_VIDEO: 'SET_SELECTED_BITE_INTRO_VIDEO',
  SET_BITE_LOADING: 'SET_BITE_LOADING',
  SET_SELECTED_BITE_COVER: 'SET_SELECTED_BITE_COVER',
  CLEAN_EDIT_AND_CREATE_BITE_STATE: 'CLEAN_EDIT_AND_CREATE_BITE_STATE',
  DELETE_BITE: 'DELETE_BITE',
  FETCH_FULL_BITES: 'FETCH_FULL_BITES',
  FETCH_FULL_BITES_WITH_CALLBACK: 'FETCH_FULL_BITES_WITH_CALLBACK',
  PREVIEW_BITE_BY_ID: 'PREVIEW_BITE_BY_ID',
  PREPARE_BITE_EDIT_SCREEN: 'PREPARE_BITE_EDIT_SCREEN',
  SET_BITES: 'SET_BITES',
  UPDATE_SELECTED_BITE: 'UPDATE_SELECTED_BITE',
  SET_BITE_IN_PROGRESS: 'SET_BITE_IN_PROGRESS',
  COPY_BITE: 'COPY_BITE',
  UPLOAD_BITE_COVER_IMAGE: 'UPLOAD_BITE_COVER_IMAGE',
  UPDATE_BITE_COVER: 'UPDATE_BITE_COVER',
  SET_CREATED_BITE_INTRO_SECTION: 'SET_CREATED_BITE_INTRO_SECTION',
  CLONE_AND_FETCH: 'CLONE_AND_FETCH',
  SET_SELECTED_BITE_NAME: 'SET_SELECTED_BITE_NAME',
  SET_BITES_LOADING_STATE: 'SET_BITES_LOADING_STATE',
  UPDATE_BITE_SECTION: 'UPDATE_BITE_SECTION',
  UPDATE_FEED_BITE: 'UPDATE_FEED_BITE',
  ON_ATTRIBUTES: 'ON_ATTRIBUTES',
  PATCH_BITE_DATA: 'PATCH_BITE_DATA',
  INDEX_BITES: 'INDEX_BITES',
  TRIGGER_CREATED_BITE_TRANSACTIONAL_COMMUNICATION: 'TRIGGER_CREATED_BITE_TRANSACTIONAL_COMMUNICATION',
};

export default Types;

export interface IInitialState {
  hasBites: boolean;
  bitesMap: { [key in string | number]: IBiteItem };
  bitesLoadingStateMap: { [key in string | number]: { isLoading: boolean; error?: string } };
  selectedBite: IBiteItem | null;
  selectedBiteIntroVideo: {
    video: Omit<IVideo, 'config'>;
    isLoading: boolean;
    error: any;
  };
  isLoading: boolean;
  bitesInProgress?: IItemInProgress;
}

export type IFetchBiteOptions = {
  extend?: ('enhancements' | 'subtitles')[];
};
