import React, { CSSProperties, ReactNode, useCallback, useState } from 'react';
import webStyled from 'styled-components';
import styled, { useTheme } from 'styled-components/native';
import RightArrowIcon from '../../../assets/icons/creationFlow/right-arrow-icon.svg';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { isRtlByLang } from '../../../locale/i18n';
import LinearGradient from '../../../components/shared/LinearGradient';
import WithAISuggestionsSign from '../../../components/WithAISuggestionsSign';

interface IProps {
  onPress?: () => void;
  renderIcon: () => ReactNode;
  title: string;
  description?: string;
  style?: CSSProperties;
  disabled?: boolean;
  smallIcons?: React.FC<ReactNode>[];
  comingOn?: number | 'soon';
  small?: boolean;
  withAISuggestions?: boolean;
  withBeta?: boolean;
  dataSet?: { [key: string]: string };
}

const ShadowedButton: React.FC<IProps> = ({
  onPress = () => {},
  renderIcon,
  title,
  description = '',
  style,
  disabled = false,
  smallIcons,
  comingOn,
  small = false,
  withAISuggestions,
  withBeta,
  dataSet,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isPressed, setIsPressed] = useState(false);

  const onMouseDown = useCallback(() => {
    setIsPressed(true);
  }, []);

  const onMouseUp = useCallback(() => {
    if (disabled) {
      return;
    }

    setIsPressed(false);
    onPress();
  }, [disabled, onPress]);

  const onMouseLeave = useCallback(() => {
    setIsPressed(false);
  }, []);

  return (
    <S.Shadow
      style={style}
      onMouseDown={onMouseDown}
      onMouseLeave={onMouseLeave}
      onMouseUp={onMouseUp}
      isPressed={isPressed}
      disabled={disabled || !!comingOn}
      small={small}
      {...Object.entries(dataSet || {}).reduce((map, [key, value]) => {
        map[`data-${key}`] = value;
        return map;
      }, {})}
    >
      <S.Content>
        {(!!disabled || !!comingOn) && <S.BackgroundGradient colors={theme.colors.disabledButtonGradient} />}
        <S.ContentBlock small={small}>
          <S.IconWrapper small={small}>{renderIcon()}</S.IconWrapper>
          <S.ContentTextWrapper small={small}>
            {comingOn && (
              <S.ComingOnContainer small={small}>
                {comingOn === 'soon' ? (
                  t('common.comingSoon')
                ) : (
                  <>
                    {t('common.comingOn')}
                    <S.ComingOnContainerBold>{comingOn}</S.ComingOnContainerBold>
                  </>
                )}
              </S.ComingOnContainer>
            )}
            <S.ContentTitle small={small}>{title}</S.ContentTitle>
            {!!description && <S.ContentDescription>{description}</S.ContentDescription>}
            {withAISuggestions && <WithAISuggestionsSign />}
            {!!smallIcons?.length && (
              <S.SmallIconsContainer>
                {smallIcons?.map((smallIcon, index) => (
                  <S.SmallIconWrapper key={index}>{smallIcon}</S.SmallIconWrapper>
                ))}
              </S.SmallIconsContainer>
            )}
          </S.ContentTextWrapper>
        </S.ContentBlock>
        {!disabled && !small && (
          <S.RightArrowIconWrapper>
            <RightArrowIcon color={theme.colors.primaryBlue} width={calcWidth(8)} height={calcHeight(15)} />
          </S.RightArrowIconWrapper>
        )}
        {withBeta && (
          <S.BetaContainer>
            <S.BetaText>Beta</S.BetaText>
          </S.BetaContainer>
        )}
      </S.Content>
    </S.Shadow>
  );
};

const S = {
  BackgroundGradient: styled(LinearGradient)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 29px;
  `,
  Shadow: webStyled.div<{ isPressed: boolean; disabled: IProps['disabled']; small?: boolean }>`
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	display: flex;
	border-radius: 29px;
	width: ${calcWidth(323)}px;
	min-height: ${({ small }) => calcHeight(small ? 96 : 110)}px;
	margin-bottom: ${calcHeight(16)}px;
	box-shadow: 0 9px 32px 0 ${({ isPressed, disabled }) =>
    isPressed || disabled ? 'transparent' : 'rgba(106, 105, 125, 0.1)'};
	background-color: ${({ isPressed, disabled }) => (isPressed && !disabled ? 'rgba(106, 105, 125, 0.1)' : '#fff')};
    border: 2px solid ${({ disabled }) => (disabled ? 'rgba(106, 105, 125, 0.1)' : 'transparent')};
  `,
  Content: styled.View`
    position: relative;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
  BetaContainer: styled.View`
    position: absolute;
    top: ${calcHeight(10)}px;
    right: ${calcWidth(14)}px;
    padding: 0px ${calcWidth(6)}px;
    height: ${calcHeight(17)}px;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.colors.backgroundPurple};
  `,
  BetaText: styled.Text`
    color: ${({ theme }) => theme.colors.lightGray40};
    font-size: 12px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-weight: 700;
    line-height: ${calcHeight(15)}px;
  `,
  Title: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s26};
    color: ${({ theme }) => theme.colors.text};
  `,
  ContentBlock: styled.View<{ small?: boolean }>`
    position: relative;
    flex-direction: ${({ small }) => (small ? 'column' : 'row')};
    align-items: center;
    padding-left: ${({ small }) => (small ? calcWidth(6) : calcWidth(30))}px;
    width: 95%;
  `,
  IconWrapper: styled.View<{ small?: boolean }>`
    margin-right: ${({ small }) => (small ? 0 : calcWidth(24))}px;
  `,
  ContentTextWrapper: styled.View<{ small?: boolean }>`
    width: ${({ small }) => (small ? '100%' : calcWidth(160))}px;
    align-items: ${({ small }) => (small ? 'center' : 'flex-start')};
  `,
  ContentTitle: styled.Text<{ small?: boolean }>`
    max-width: ${({ small }) => (small ? calcWidth(100) : calcWidth(200))}px;
    white-space: break-spaces;
    text-align: ${isRtlByLang[i18next.language] ? 'right' : 'left'};
    align-self: ${isRtlByLang[i18next.language] ? 'flex-end' : 'flex-start'};
    margin-top: ${({ small }) => (small ? calcHeight(6) : 0)}px;
    font-size: ${({ theme, small }) => (small ? theme.fontSizes.s12 : theme.fontSizes.s16)};
    color: ${({ theme }) => theme.colors.text};
    font-weight: ${({ small }) => (small ? 'regular' : 'bold')};
    margin-bottom: ${calcHeight(2)}px;
  `,
  ContentDescription: styled.Text`
    width: ${calcWidth(200)}px;
    font-size: ${({ theme }) => theme.fontSizes.s13};
    color: ${({ theme }) => theme.colors.gray11};
    line-height: ${({ theme }) => theme.fontSizes.s13 * 1.31};
  `,
  SmallIconsContainer: styled.View`
    flex-direction: row;
    align-content: center;
    margin-top: ${calcHeight(10)}px;
    min-height: ${calcWidth(21)}px;
    flex: 1;
  `,
  SmallIconWrapper: styled.View`
    width: ${calcWidth(21)}px;
    height: ${calcWidth(21)}px;
    margin-right: ${calcWidth(9)}px;
  `,
  ComingOnContainer: styled.Text<{ small?: boolean }>`
    height: ${calcHeight(16)}px;
    margin-bottom: ${calcHeight(8)}px;
    border-radius: 5px;
    padding: ${calcHeight(1)}px ${calcWidth(5)}px;
    border: solid 1px ${({ theme }) => theme.colors.greenChoose};
    background-color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSizes.s10};
    line-height: ${({ theme }) => theme.fontSizes.s12};
    text-align: center;
    align-items: center;
    ${({ small }) =>
      small
        ? `
        white-space: nowrap;
        background-color: ${({ theme }) => theme.colors.shadowColor};
        min-width: ${calcWidth(90)}px;
        position: absolute;
        bottom: -${calcHeight(30)}px;
        rotate: -2deg;
    `
        : ''}
  `,
  ComingOnContainerBold: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s10};
    font-weight: bold;
  `,
  RightArrowIconWrapper: styled.View`
    left: -${calcWidth(6)}px;
  `,
};

export default ShadowedButton;
