import Types from './bite.types';
import { IBiteItem, ICopyBiteAction } from '../../types/bite';
import { IItemInProgressActionPayload, IOnAttributesPayload, IUploadBiteCover } from '../../types/common';
import { IMedia } from '../../types/media';
import { IVideo } from '../videoEditor/videoEditor.types';

export const fetchHasBites = () => ({
  type: Types.FETCH_HAS_BITES,
});
export const setHasBites = (payload: boolean) => ({
  type: Types.SET_HAS_BITES,
  payload,
});

export const fetchFullBites = (bites: number[]) => ({
  type: Types.FETCH_FULL_BITES,
  payload: bites,
});
export const fetchFullBitesWithCallback = (payload: {
  biteIds: number[];
  onSuccess?: (props: { bites: IBiteItem[] }) => void;
  onError?: () => void;
}) => ({
  type: Types.FETCH_FULL_BITES_WITH_CALLBACK,
  payload,
});

export const cloneAndFetchBite = (biteId: number, callback?: () => void) => ({
  type: Types.CLONE_AND_FETCH,
  payload: {
    biteId,
    callback,
  },
});

export const fetchBiteToEditRequest = (biteId: number, callback?: () => void) => ({
  type: Types.FETCH_BITE_TO_EDIT_REQUEST,
  payload: {
    biteId,
    callback,
  },
});

export const prepareBiteEditScreen = (biteId: number) => ({
  type: Types.PREPARE_BITE_EDIT_SCREEN,
  payload: {
    biteId,
  },
});

export const setBiteToEdit = (bite: any) => ({
  type: Types.SET_BITE_TO_EDIT,
  payload: bite,
});

export const setSelectedBiteIntroVideo = (payload: {
  video: Omit<IVideo, 'config'>;
  isLoading: boolean;
  error: any;
}) => ({
  type: Types.SET_SELECTED_BITE_INTRO_VIDEO,
  payload,
});

export const updateBiteSection = ({ biteId, section }) => ({
  type: Types.UPDATE_BITE_SECTION,
  payload: {
    biteId,
    section,
  },
});

export const setBiteLoading = (state: boolean) => ({
  type: Types.SET_BITE_LOADING,
  payload: state,
});

export const setBitesLoadingState = (loadingBitesMap: { biteId: number; isLoading: boolean; error: string }[]) => ({
  type: Types.SET_BITES_LOADING_STATE,
  payload: loadingBitesMap,
});

export const setSelectedBiteCover = (uri: string) => ({
  type: Types.SET_SELECTED_BITE_COVER,
  payload: uri,
});

export const deleteBite = (biteId: number) => ({
  type: Types.DELETE_BITE,
  payload: biteId,
});

export const cleanEditAndCreateBiteState = () => ({
  type: Types.CLEAN_EDIT_AND_CREATE_BITE_STATE,
});

export const setBites = (payload: Partial<IBiteItem>[]) => ({
  type: Types.SET_BITES,
  payload,
});

export const updateSelectedBite = (payload: Partial<IBiteItem>) => ({
  type: Types.UPDATE_SELECTED_BITE,
  payload,
});

export const setBiteInProgress = (payload: IItemInProgressActionPayload) => ({
  type: Types.SET_BITE_IN_PROGRESS,
  payload,
});

export const updateBiteCover = (payload: IUploadBiteCover) => ({
  type: Types.UPDATE_BITE_COVER,
  payload,
});

export const uploadBiteCoverImage = (payload: IUploadBiteCover) => ({
  type: Types.UPLOAD_BITE_COVER_IMAGE,
  payload,
});

export const copyBite = (payload: ICopyBiteAction) => ({
  type: Types.COPY_BITE,
  payload,
});

export const setCreatedBiteIntroSection = (media: IMedia | null) => ({
  type: Types.SET_CREATED_BITE_INTRO_SECTION,
  payload: media,
});

export const setSelectedBiteName = (name: string) => ({
  type: Types.SET_SELECTED_BITE_NAME,
  payload: name,
});

export const updateFeedBite = (payload: Partial<{ id: number; bite: Partial<IBiteItem> }>) => ({
  type: Types.UPDATE_FEED_BITE,
  payload,
});

export const onAttributes = (payload: IOnAttributesPayload) => ({
  type: Types.ON_ATTRIBUTES,
  payload,
});

export const updateBiteData = (payload: { biteId: number; body: Partial<IBiteItem> }) => ({
  type: Types.PATCH_BITE_DATA,
  payload,
});

export const indexBites = (payload: { biteIds: number[] }) => ({
  type: Types.INDEX_BITES,
  payload,
});

export const triggerCreatedBiteTransactionalCommunication = (payload: { biteId: number }) => ({
  type: Types.TRIGGER_CREATED_BITE_TRANSACTIONAL_COMMUNICATION,
  payload,
});
