import React, { memo, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import defaultTheme from '../../../../themes';
import CloseIcon from '../../../../assets/icons/videoEditor/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import { overlaySelector } from '../../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.selectors';
import CloudSearchItem from '../CloudSearchItem';
import Checkbox from '../../../../components/shared/Checkbox/Checkbox';
import { setFlaggedToReset, setOverlay } from '../../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.slice';
import Button from '../../../../components/shared/buttons/Button/Button';
import { toggleIsBrandCloudAsset } from '../../../../store/cloudAssets/cloudAssets.slice';
import { DO_NOT_SHOW_AGAIN_KEY } from './AddToBrandOverlay.constants';

const buttonStyle = {
  height: 47,
  width: 213,
};

const AddToBrandOverlay = () => {
  const dispatch = useDispatch();

  const overlay = useSelector(overlaySelector);

  const [doNotShowAgain, setDoNotShowAgain] = useState(false);

  const doNotShowAgainInitial = useMemo(() => localStorage.getItem(DO_NOT_SHOW_AGAIN_KEY), []);

  const handleClose = useCallback(() => {
    dispatch(setOverlay(null));
  }, [dispatch]);

  const handleChangeDoNotShowAgain = useCallback(() => {
    setDoNotShowAgain((prev) => {
      const newValue = !prev;

      if (newValue) {
        localStorage.setItem(DO_NOT_SHOW_AGAIN_KEY, 'true');
      } else {
        localStorage.removeItem(DO_NOT_SHOW_AGAIN_KEY);
      }

      return newValue;
    });
  }, []);

  const handleContinue = useCallback(() => {
    dispatch(
      toggleIsBrandCloudAsset({
        cloudAssetId: overlay.cloudAssetId,
      }),
    );

    dispatch(
      setFlaggedToReset({
        brand: true,
      }),
    );

    handleClose();
  }, [dispatch, handleClose, overlay.cloudAssetId]);

  return (
    <S.Container>
      <S.Content>
        <S.Title>Publish in Brand Library</S.Title>
        <S.Description>
          Publishing this element in the Brand Library will make it public and accessible to all video editors within
          the company
        </S.Description>
        <S.PreviewContainer>
          <CloudSearchItem withFeaturedIcons={false} cloudAssetId={overlay.cloudAssetId} />
        </S.PreviewContainer>
        {!doNotShowAgainInitial && (
          <S.DoNotShowAgainContainer>
            <Checkbox checked={doNotShowAgain} onChange={handleChangeDoNotShowAgain} />
            <S.DoNotShowAgainLabel onClick={handleChangeDoNotShowAgain}>
              Do not show this message again
            </S.DoNotShowAgainLabel>
          </S.DoNotShowAgainContainer>
        )}
        <S.ButtonContainer>
          <Button
            isShadowed
            text={'Continue'}
            style={buttonStyle}
            fill={defaultTheme.colors.primaryBlue}
            border={defaultTheme.colors.primaryBlue}
            textColor={defaultTheme.colors.white}
            onPress={handleContinue}
          />
        </S.ButtonContainer>
      </S.Content>
      <S.CloseContainer onClick={handleClose}>
        <CloseIcon />
      </S.CloseContainer>
    </S.Container>
  );
};
export default memo(AddToBrandOverlay);

export const S = {
  Container: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${defaultTheme.colors.transparentGray1};
  `,
  Content: styled.div`
    display: flex;
    width: 294px;
    flex-direction: column;
    align-items: center;
  `,
  Title: styled.div`
    color: ${defaultTheme.colors.black};
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 15px;
    font-weight: 700;
    line-height: 16px;
  `,
  Description: styled.div`
    margin-top: 24px;
    color: ${defaultTheme.colors.black};
    text-align: center;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
  `,
  PreviewContainer: styled.div`
    margin-top: 30px;
    width: 294px;
    border: 8px solid #fff;
    border-radius: 10px;
    background: ${defaultTheme.colors.white};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  `,
  DoNotShowAgainContainer: styled.div`
    margin-top: 30px;
    display: flex;
    align-items: center;
  `,
  DoNotShowAgainLabel: styled.div`
    margin-left: 8px;
    color: ${defaultTheme.colors.black};
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
  `,
  ButtonContainer: styled.div`
    margin-top: 39px;
  `,
  CloseContainer: styled.div`
    position: absolute;
    top: 17px;
    right: 17px;
    cursor: pointer;
  `,
};
